import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Button, Select } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer } from '../../../components/Form/index';
import { useSpecialist } from '../../../hooks/Specialist';
import { useContent } from '../../../hooks/Content';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import debounce from 'lodash.debounce';

const subjects = [
  'Amamentação',
  'Aprendizado',
  'Autonomia da criança',
  'Birra',
  'Brincadeiras',
  'Conversando com a criança',
  'Comportamento da criança',
  'Crianças',
  'Exemplo dos pais',
  'Família',
  'Limites',
  'Recém nascido',
  'Rotina da criança',
  'Sentimentos da criança',
];

const SearchContent = ({ loadingFunction }) => {
  const [loadingSpecialist, setLoadingSpecialist] = useState(false);
  const {
    specialistsData,
    getAllSpecialists,
    clearSpecialistsArray,
  } = useSpecialist();
  const { specialistArray } = specialistsData;
  const { getAllContent } = useContent();

  useEffect(() => {
    clearSpecialistsArray();
    const initialFetchSpecialist = async () => {
      setLoadingSpecialist(true);
      await getAllSpecialists({
        name: '',
        status: true,
      });
      setLoadingSpecialist(false);
    };
    initialFetchSpecialist();
  }, [getAllSpecialists, clearSpecialistsArray]);

  const handleSearch = async (values) => {
    loadingFunction(true);
    await getAllContent(values);
    loadingFunction(false);
  };

  const fetchSpecialist = async (params) => {
    setLoadingSpecialist(true);
    await getAllSpecialists(params);
    setLoadingSpecialist(false);
  };

  const searchSpecialist = debounce(async (info) => {
    const params = {
      name: info,
      status: true,
    };

    await fetchSpecialist(params);
  }, 300);

  return (
    <FormContainer>
      <FormTitle>Filtrar conteúdo</FormTitle>
      <Formik
        initialValues={{
          title: '',
          category: '',
          subject: '',
          specialist: '',
          status: '',
        }}
        onSubmit={handleSearch}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Título:</FormLabel>
                <Input
                  id="campo_titulo"
                  name="title"
                  style={{ width: '100%' }}
                  value={formik.values.title}
                  onChange={(e) =>
                    formik.setFieldValue('title', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Categoria:</FormLabel>
                <Select
                  allowClear
                  id="campo_categoria"
                  style={{ width: '100%' }}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                  onChange={(value) => formik.setFieldValue('category', value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value="Curso">Curso</Select.Option>
                  <Select.Option value="Série">Série</Select.Option>
                  <Select.Option value="Playlist">Playlist</Select.Option>
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Assunto:</FormLabel>
                <Select
                  allowClear
                  id="campo_assunto"
                  style={{ width: '100%' }}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  onChange={(value) => formik.setFieldValue('subject', value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {subjects.map((s) => (
                    <Select.Option key={s} value={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </FieldContainer>

              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Especialista:</FormLabel>
                <Select
                  id="campo_especialista"
                  style={{ width: '100%' }}
                  placeholder="Digite o nome do especialista"
                  showSearch
                  onSearch={searchSpecialist}
                  value={formik.values.specialist}
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue('specialist', value)
                  }
                  notFoundContent={loadingSpecialist ? <LoadSpinner /> : null}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {specialistArray.map((s) => (
                    <Select.Option key={s.idEspecialista} value={s.nome}>
                      {s.nome}
                    </Select.Option>
                  ))}
                </Select>
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Situação:</FormLabel>
                <Select
                  id="campo_situacao"
                  allowClear
                  style={{ width: '100%' }}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('status', value)}
                  value={formik.values.status}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value>Ativo</Select.Option>
                  <Select.Option value={false}>Desativado</Select.Option>
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <Spacer style={{ margin: '15px 0 0 auto' }}>
              <Button type="primary" onClick={formik.handleSubmit}>
                Pesquisar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;

const FormTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 800;
`;

export default SearchContent;
