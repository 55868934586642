import React, { useEffect, useState } from 'react';
import { Modal, Select, Button, message } from 'antd';
import { FormLabel, Spacer } from '../../Form/index';
import LoadSpinner from '../../shared/LoadSpinner';
import { useTag } from '../../../hooks/Tag';

const AddTag = ({ currentTags, onCancel, handleTags, contentId }) => {
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [selectSearch, setSelectSearch] = useState('');

  const { getAllTags, tagsData, createTag } = useTag();
  const { tagsArray, displayTagsObj } = tagsData;

  const [selectValue, setSelectValue] = useState(() => {
    return currentTags.map((t) => displayTagsObj[t.idTag]);
  });

  useEffect(() => {
    const fetchTags = async () => {
      setLoadingTags(true);
      await getAllTags();
      setLoadingTags(false);
    };
    fetchTags();
  }, [getAllTags]);

  const handleCreateTag = async () => {
    if (selectSearch.trim().length < 4) {
      message.error('Uma tag deve ter pelo menos 4 caracteres');
      return;
    }
    setLoadingCreate(true);
    await createTag(selectSearch.trim());
    setLoadingCreate(false);
  };

  const handleRequest = async () => {
    const selectedTags = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectValue.length; i++) {
      const v = selectValue[i];
      const tag = tagsArray.filter((t) => t.nomeTag === v)[0];
      selectedTags.push(tag);
    }

    setLoadingRequest(true);
    await handleTags(selectedTags, contentId);
    setLoadingRequest(false);
  };

  return (
    <Modal visible footer={null} onCancel={onCancel}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Spacer>
          <FormLabel>Tags:</FormLabel>

          <Select
            showSearch
            onSearch={(info) => setSelectSearch(info)}
            allowClear
            value={selectValue}
            mode="multiple"
            style={{ width: '100%' }}
            onChange={(info) => setSelectValue(info)}
            notFoundContent={
              loadingTags ? (
                <LoadSpinner />
              ) : (
                <Button loading={loadingCreate} onClick={handleCreateTag}>
                  Criar tag
                </Button>
              )
            }
          >
            {tagsArray?.map((t) => (
              <Select.Option key={t.idTag} value={t.nomeTag}>
                {displayTagsObj[t.idTag]}
              </Select.Option>
            ))}
          </Select>
        </Spacer>

        <Spacer style={{ marginLeft: 'auto' }}>
          <Button
            type="primary"
            onClick={handleRequest}
            loading={loadingRequest}
          >
            Salvar
          </Button>
        </Spacer>
      </div>
    </Modal>
  );
};

export default AddTag;
