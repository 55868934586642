export const USER_PERMS = {
  CREATE: 'Criar Usuário',
  READ: 'Visualizar Usuário',
  UPDATE: 'Alterar Usuário',
  DELETE: 'Desativar Usuário',
};

export const ROLE_PERMS = {
  CREATE: 'Criar Perfil',
  READ: 'Visualizar Perfil',
  UPDATE: 'Alterar Perfil',
  DELETE: 'Desativar Perfil',
};

export const PERMISSION_PERMS = {
  UPDATE: 'Alterar Permissão',
  READ: 'Visualizar Permissão',
};

export const SUBSCRIPTION_PERMS = {
  CREATE: 'Criar Plano',
  READ: 'Visualizar Plano',
  UPDATE: 'Alterar Plano',
  DELETE: 'Desativar Plano',
};

export const SPECIALIST_PERMS = {
  CREATE: 'Criar Especialista',
  READ: 'Visualizar Especialista',
  UPDATE: 'Alterar Especialista',
  DELETE: 'Desativar Especialista',
};

export const CONTENT_PERMS = {
  CREATE: 'Criar Conteudo',
  READ: 'Visualizar Conteudo',
  UPDATE: 'Alterar Conteudo',
  DELETE: 'Desativar Conteudo',
};

export const MODULE_PERMS = {
  CREATE: 'Criar Módulo',
  READ: 'Visualizar Módulo',
  UPDATE: 'Alterar Módulo',
  DELETE: 'Desativar Módulo',
};
