import * as yup from 'yup';

export const addUserSchema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório.'),
  email: yup
    .string()
    .email('Digite um email válido!')
    .required('Digite um email válido!'),
  phoneNumber: yup.string().optional(),
  role: yup.string().required('Este campo é obrigatório.'),
});
