import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';

const PermContext = createContext();

const PermProvider = ({ children }) => {
  const [userPerms, setUserPerms] = useState(() => {
    const perms = localStorage.getItem('@MundoEmCores:permissions');
    if (perms) {
      return JSON.parse(perms);
    }

    return null;
  });

  const getPermissions = useCallback(async () => {
    try {
      const response = await axios.get(`/Permissao`);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para ativar especialistas.');
      } else if (error.response?.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao buscar permissões!');
      }
    }
  }, []);

  const getPermissionByRole = useCallback(async (id) => {
    try {
      const response = await axios.get(`/PermissaoPerfil/${id}`);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para ativar especialistas.');
      } else if (error.response?.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao buscar permissões!');
      }
    }
  }, []);

  const changePermissions = useCallback(async (reqBody) => {
    try {
      const response = await axios.post(`/PermissaoPerfil`, reqBody);

      message.success('Permissões atualizadas com sucesso!');
      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para ativar especialistas.');
      } else if (error.response?.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar permissões!');
      }
    }
  }, []);

  const clearSignedUserPerms = useCallback(() => {
    localStorage.removeItem('@MundoEmCores:permissions');
    setUserPerms(null);
  }, []);

  const setSignedUserPerms = useCallback((perms) => {
    setUserPerms(perms);
    localStorage.setItem('@MundoEmCores:permissions', JSON.stringify(perms));
  }, []);

  return (
    <PermContext.Provider
      value={{
        permData: { signedUserPerms: userPerms },
        getPermissions,
        getPermissionByRole,
        changePermissions,
        clearSignedUserPerms,
        setSignedUserPerms,
      }}
    >
      {children}
    </PermContext.Provider>
  );
};

const usePerm = () => {
  const context = useContext(PermContext);

  if (!context) {
    throw new Error('usePerm must be used within an PermProvider');
  }

  return context;
};

export { PermProvider, usePerm };
