import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadSpinner = () => {
  return (
    <div style={{ display: 'flex' }}>
      <Spin
        style={{ margin: '0 auto' }}
        indicator={<LoadingOutlined spin />}
        size="large"
      />
    </div>
  );
};

export default LoadSpinner;
