/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout, Avatar, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LogoutModal from '../LogoutModal';
import { useAuth } from '../../../hooks/Auth';
import DropdownMenu from './DropdownMenu';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/mundoemcoresnegativo.png';

const { Header } = Layout;

const LayoutHeader = () => {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const { authData } = useAuth();
  const history = useHistory();

  return (
    <Header
      style={{
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LogoImage src={logo} alt="logo do mundo em cores" />

      <Dropdown
        overlay={
          <DropdownMenu
            handleLogout={() => setLogoutModalVisible(true)}
            handleUserData={() => history.push('/dashboard/meus-dados')}
          />
        }
        placement="bottomCenter"
      >
        <UserDataContainer id="menu-usuario">
          <Avatar
            shape="circle"
            icon={<UserOutlined />}
            style={{ background: 'transparent', border: 'solid 1px white' }}
          />
          <UserName>{authData.email}</UserName>
        </UserDataContainer>
      </Dropdown>

      {logoutModalVisible ? (
        <LogoutModal visible onCancel={() => setLogoutModalVisible(false)} />
      ) : null}
    </Header>
  );
};

const UserDataContainer = styled.button`
  margin-left: auto;
  margin-right: 2%;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const UserName = styled.p`
  margin: 0 10px;
  color: white;
`;

const LogoImage = styled.img`
  margin-left: 15px;
`;

export default LayoutHeader;
