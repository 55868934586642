import React from 'react';
import styled from 'styled-components';
import { Input, Button, Select, InputNumber, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer } from '../../../components/Form/index';
import { useSubscription } from '../../../hooks/Subscription';

const dateFormat = 'DD/MM/YYYY';

const SearchSubscriptions = ({ loadingFunction }) => {
  const { getSubscriptions } = useSubscription();

  const handleSearch = async (values) => {
    loadingFunction(true);
    await getSubscriptions(values);
    loadingFunction(false);
  };

  return (
    <FormContainer title="Pesquisar Planos" style={{ marginBottom: '10px' }}>
      <FormTitle>Filtrar planos</FormTitle>
      <Formik
        initialValues={{
          name: '',
          initialDate: '',
          finalDate: '',
          timeSpan: '',
          situacao: '',
          price: 0,
        }}
        onSubmit={handleSearch}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Nome do plano:</FormLabel>
                <Input
                  id="campo_nome_plano"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Periodicidade:</FormLabel>
                <Select
                  id="campo_periodicidade"
                  placeholder="Selecione uma opção"
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('timeSpan', value)}
                  value={formik.values.timeSpan}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value="Mensal">Mensal</Select.Option>
                  <Select.Option value="Semestral">Semestral</Select.Option>
                  <Select.Option value="Anual">Anual</Select.Option>
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Vigência inicial:</FormLabel>
                <DatePicker
                  id="campo_data_inicio"
                  locale={locale}
                  onBlur={formik.handleBlur}
                  format={dateFormat}
                  value={formik.values.initialDate}
                  onChange={(e) => formik.setFieldValue('initialDate', e)}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Vigência final:</FormLabel>
                <DatePicker
                  id="campo_data_fim"
                  locale={locale}
                  onBlur={formik.handleBlur}
                  format={dateFormat}
                  value={formik.values.finalDate}
                  onChange={(e) => formik.setFieldValue('finalDate', e)}
                />
              </FieldContainer>
            </HorizontalContainer>

            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Valor (R$):</FormLabel>
                <InputNumber
                  id="campo_valor_plano"
                  onChange={(value) => formik.setFieldValue('price', value)}
                  value={formik.values.price}
                  onBlur={formik.handleBlur}
                  precision={2}
                  step={0.01}
                  decimalSeparator=","
                  style={{ width: '100%' }}
                  min={0}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Situação</FormLabel>
                <Select
                  id="campo_situacao"
                  allowClear
                  style={{ width: '100%' }}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('situacao', value)}
                  value={formik.values.situacao}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value>Ativo</Select.Option>
                  <Select.Option value={false}>Desativado</Select.Option>
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <Spacer style={{ margin: '15px 0 0 auto' }}>
              <Button type="primary" onClick={formik.handleSubmit}>
                Pesquisar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;

const FormTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 800;
`;

export default SearchSubscriptions;
