import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

const ErrorMsg = ({ name }) => {
  return (
    <ErrorMessage name={name}>{(msg) => <Error>{msg}</Error>}</ErrorMessage>
  );
};

const Error = styled.div`
  font-size: 0.8rem;
  margin: 1px 0;
  color: red;
`;

export default ErrorMsg;
