import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { getUrlQuery } from './util';

const ContentContext = createContext();

const ContentProvider = ({ children }) => {
  const [contentArray, setContentArray] = useState([]);
  const [currentContent, setCurrentContent] = useState(null);
  const [currentSections, setCurrentSections] = useState([]);
  const [highlightArray, setHighlightArray] = useState([]);

  const getAllContent = useCallback(async (params = {}) => {
    const newParams = {
      Titulo: params.title || undefined,
      Categoria: params.category || undefined,
      Assunto: params.subject || undefined,
      Especialista: params.specialist || undefined,
      IdEspecialista: params.specialistId || undefined,
      Situacao: params.status,
    };

    try {
      const response = await axios.get(`/Conteudo?${getUrlQuery(newParams)}`);
      setContentArray(response.data.result);
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao visualizar módulo!');
      }

      return false;
    }
  }, []);

  const getContentById = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Conteudo/${id}`);

      setCurrentContent(response.data.result);

      return response.data.result;
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao visualizar módulo!');
      }
    }
  }, []);

  const createContent = useCallback(async (body) => {
    try {
      const postBody = {
        categoria: body.category,
        titulo: body.title,
        duracao: body.duration,
        situacao: false,
        assunto: body.subject,
        publicoAlvo: body.audience,
        descricao: body.description,
        objetivo: body.objective,
        cobrancaAdicional: body.aditional,
        valorCobranca: body.aditionalValue,
        valorPromo: body.aditionalPromoValue,
        certificado: body.hasCertificate,
        idEspecialista: body.specialist,
        fotoCapa: body.contentPhoto,
        fotoDetalhe: body.detailPhoto,
        vigenciaInicial: body.initialDate,
        vingenciaFinal: body.finalDate,
        exibeOffline: body.allowOffline,
      };

      if (!body.aditional) {
        delete postBody.valorCobranca;
        delete postBody.vigenciaInicial;
        delete postBody.vingenciaFinal;
        delete postBody.valorPromo;
      }

      const response = await axios.post('/Conteudo', postBody);

      message.success(
        'Conteúdo cadastrado com sucesso. Para disponibilizá-lo para o cliente é necessário realizar a ativação.'
      );
      return response.data.codigo;
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para criar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao criar conteúdo!');
      }

      return false;
    }
  }, []);

  const updateContent = useCallback(async (body) => {
    try {
      const postBody = {
        categoria: body.category,
        titulo: body.title,
        duracao: body.duration,
        situacao: true,
        assunto: body.subject,
        publicoAlvo: body.audience,
        descricao: body.description,
        objetivo: body.objective,
        cobrancaAdicional: body.aditional,
        valorCobranca: body.aditionalValue,
        valorPromo: body.aditionalPromoValue,
        certificado: body.hasCertificate,
        idEspecialista: body.specialist,
        fotoCapa: body.contentPhoto,
        fotoDetalhe: body.detailPhoto,
        vigenciaInicial: body.initialDate,
        vingenciaFinal: body.finalDate,
        exibeOffline: body.allowOffline,
      };

      if (!body.aditional) {
        delete postBody.valorCobranca;
        delete postBody.vigenciaInicial;
        delete postBody.vingenciaFinal;
        delete postBody.valorPromo;
      }

      await axios.put(`/Conteudo/${body.id}`, postBody);

      message.success('Conteúdo atualizado com sucesso!');
      return true;
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para alterar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar conteúdo!');
      }

      return false;
    }
  }, []);

  const deactivateContent = useCallback(
    async (id) => {
      try {
        await axios.delete(`/Conteudo/${id}`);
        await getAllContent();

        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar conteúdo!');
        }

        return false;
      }
    },
    [getAllContent]
  );

  const activateContent = useCallback(
    async (id, content) => {
      try {
        const postBody = {
          ...content,
          situacao: true,
        };
        await axios.put(`/Conteudo/${id}`, postBody);

        await getAllContent();

        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar conteúdo!');
        }

        return false;
      }
    },
    [getAllContent]
  );

  const getLessons = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Aula/${id}/Modulo`);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para alterar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar aula!');
      }
      return null;
    }
  }, []);

  const getSections = useCallback(
    async (id) => {
      try {
        const response = await axios.get(`/Modulo/${id}/Conteudo`);

        const lessonsPromises = response.data.result.map(async (s) =>
          getLessons(s.idModulo)
        );

        const lessonsArray = await Promise.all(lessonsPromises);

        const sections = response.data.result.map((sec, index) => {
          return { ...sec, lessons: lessonsArray[index] };
        });

        setCurrentSections(sections);
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar aula!');
        }
        setCurrentSections([]);
      }
    },
    [getLessons]
  );

  const createContentSection = useCallback(
    async ({ contentId, title }) => {
      try {
        const postBody = {
          titulo: title,
          idConteudo: contentId,
          situacao: true,
        };

        await axios.post('/Modulo', postBody);

        await getContentById(contentId);
        await getSections(contentId);

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao criar módulo!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const updateContentSection = useCallback(
    async ({ contentId, title, id, status }) => {
      try {
        const putBody = {
          titulo: title,
          idConteudo: contentId,
          situacao: status,
          idModulo: id,
        };

        await axios.put(`/Modulo/${id}`, putBody);

        await getContentById(contentId);
        await getSections(contentId);

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar módulo!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const updateContentSubscription = useCallback(
    async (body) => {
      try {
        await axios.post(`/PlanoConteudo`, body.bodyArray);
        await getContentById(body.contentId);

        message.success('Conteúdo atualizado com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao atualizar conteúdo!');
        }

        return false;
      }
    },
    [getContentById]
  );

  const getContentSub = useCallback(async (contentId) => {
    try {
      const response = await axios.get(`/PlanoConteudo/${contentId}`);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar planos.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao visualizar plano!');
      }
      return [];
    }
  }, []);

  const updateContentTags = useCallback(
    async ({ bodyArray, contentId }) => {
      try {
        await axios.post(`/TagConteudo`, bodyArray);
        await getContentById(contentId);
        await getSections(contentId);

        message.success('Conteúdo atualizado com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao atualizar conteúdo!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const updateLessonTags = useCallback(
    async ({ bodyArray, contentId }) => {
      try {
        await axios.post(`/TagAula`, bodyArray);
        await getContentById(contentId);
        await getSections(contentId);

        message.success('Conteúdo atualizado com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao atualizar conteúdo!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const createHighlights = useCallback(async (reqBody) => {
    try {
      const response = await axios.post(`/ConteudoDestaque`, reqBody);

      message.success('Destaques atualizado com sucesso!');
      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para ativar destaques.');
      } else if (error.response?.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar destaque!');
      }
    }
  }, []);

  const getAllHighlights = useCallback(async () => {
    try {
      const response = await axios.get(`/ConteudoDestaque`);

      setHighlightArray(response.data.result);
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para ativar destaques.');
      } else if (error.response?.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar destaque!');
      }
    }
  }, []);

  const clearCurrentContent = useCallback(() => {
    setCurrentContent(null);
    setCurrentSections([]);
  }, []);

  return (
    <ContentContext.Provider
      value={{
        contentData: {
          contentArray,
          currentContent,
          currentSections,
          highlightArray,
        },
        createContent,
        updateContent,
        deactivateContent,
        createContentSection,
        updateContentSection,
        getAllContent,
        getContentById,
        updateContentSubscription,
        getContentSub,
        updateContentTags,
        updateLessonTags,
        getSections,
        clearCurrentContent,
        createHighlights,
        getAllHighlights,
        activateContent,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

const useContent = () => {
  const context = useContext(ContentContext);

  if (!context) {
    throw new Error('useContent must be used within an ContentProvider');
  }

  return context;
};

export { ContentProvider, useContent };
