import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSpecialist } from '../../../hooks/Specialist';

const ToggleSpecialist = ({ onCancel, selected }) => {
  const [loading, setLoading] = useState(false);

  const { activateSpecialist, deleteSpecialist } = useSpecialist();

  const handleRequest = async () => {
    let result = null;
    setLoading(true);
    if (!selected.situacao) {
      result = await activateSpecialist(selected.idEspecialista);
    } else {
      result = await deleteSpecialist(selected.idEspecialista);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible
      title={
        !selected.situacao ? 'Ativar Especialista' : 'Desativar Especialista'
      }
      onCancel={onCancel}
      okText={!selected.situacao ? 'Ativar' : 'Desativar'}
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      <p>
        Deseja realmente {!selected.situacao ? 'ativar' : 'desativar'} este
        especialista?
      </p>
    </Modal>
  );
};

export default ToggleSpecialist;
