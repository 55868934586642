import React from 'react';
import styled from 'styled-components';
import {
  ProfileOutlined,
  TeamOutlined,
  IdcardOutlined,
  DollarOutlined,
  SmileOutlined,
  PlaySquareOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { usePerm } from '../../../hooks/Permission';
import {
  ROLE_PERMS,
  USER_PERMS,
  SPECIALIST_PERMS,
  SUBSCRIPTION_PERMS,
  CONTENT_PERMS,
  PERMISSION_PERMS,
} from '../../../constants/Permissions';

const { Sider } = Layout;

const sidebarRoutes = {
  users: 'usuarios',
  roles: 'perfis',
  perms: 'permissoes',
  subscriptions: 'planos',
  specialists: 'especialistas',
  content: 'tabela-conteudos',
  highlightsContent: 'conteudo-destaques',
};

const Sidebar = () => {
  const { permData } = usePerm();
  const { signedUserPerms } = permData;
  const history = useHistory();
  const match = useRouteMatch();
  const currentRoute = history.location.pathname.split('/')[2];

  return (
    <Sider theme="light" width={250}>
      <SidebarContainer>
        <Menu mode="inline" selectedKeys={[currentRoute]}>
          {signedUserPerms[USER_PERMS.READ] ? (
            <Menu.Item
              id="rota-usuarios"
              key={sidebarRoutes.users}
              icon={<TeamOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.users}`)
              }
            >
              Usuários
            </Menu.Item>
          ) : null}

          {signedUserPerms[ROLE_PERMS.READ] ? (
            <Menu.Item
              id="rota-perfis-de-usuarios"
              key={sidebarRoutes.roles}
              icon={<ProfileOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.roles}`)
              }
            >
              Perfis de Usuários
            </Menu.Item>
          ) : null}

          {signedUserPerms[PERMISSION_PERMS.READ] ? (
            <Menu.Item
              id="rota-permissoes"
              key={sidebarRoutes.perms}
              icon={<IdcardOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.perms}`)
              }
            >
              Permissões
            </Menu.Item>
          ) : null}

          {signedUserPerms[SPECIALIST_PERMS.READ] ? (
            <Menu.Item
              id="rota-especialistas"
              key={sidebarRoutes.specialists}
              icon={<SmileOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.specialists}`)
              }
            >
              Especialistas
            </Menu.Item>
          ) : null}

          {signedUserPerms[SUBSCRIPTION_PERMS.READ] ? (
            <Menu.Item
              id="rota-planos"
              key={sidebarRoutes.subscriptions}
              icon={<DollarOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.subscriptions}`)
              }
            >
              Planos
            </Menu.Item>
          ) : null}

          {signedUserPerms[CONTENT_PERMS.READ] ? (
            <Menu.Item
              id="rota-conteudos"
              key={sidebarRoutes.content}
              icon={<PlaySquareOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.content}`)
              }
            >
              Conteúdos
            </Menu.Item>
          ) : null}

          {signedUserPerms[CONTENT_PERMS.READ] ? (
            <Menu.Item
              id="rota-conteudo-destaques"
              key={sidebarRoutes.highlightsContent}
              icon={<StarOutlined />}
              onClick={() =>
                history.push(`${match.url}/${sidebarRoutes.highlightsContent}`)
              }
            >
              Conteúdo Destaques
            </Menu.Item>
          ) : null}
        </Menu>
      </SidebarContainer>
    </Sider>
  );
};

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Sidebar;
