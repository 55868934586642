import React, { useState, useEffect } from 'react';
import {
  Container,
  ColorsImage,
  FormContainer,
  LogoImage,
  LoginMainText,
  LoginSecondaryText,
} from './styles';
import { Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Formik, Form } from 'formik';
import { FormLabel, ErrorMsg, Spacer } from '../../components/Form/index';
import logoHorizontal from '../../assets/images/logo_horizontal.png';
import { loginSchema } from './formValidation';
import loginBackground from '../../assets/images/bg_image.svg';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';
import { useUser } from '../../hooks/User';
import { usePerm } from '../../hooks/Permission';

const Login = ({ history }) => {
  const { signIn, authData } = useAuth();
  const { getUserById } = useUser();
  const { getPermissionByRole, setSignedUserPerms, permData } = usePerm();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const roleId = currentUser?.perfilId;

  const { signedUserPerms } = permData;
  const idUser = authData?.idUser;

  useEffect(() => {
    if (idUser) {
      const fetchUserInfo = async () => {
        const currentUserData = await getUserById(idUser);
        setCurrentUser(currentUserData);
      };
      fetchUserInfo();
    }
  }, [idUser, getUserById]);

  useEffect(() => {
    const fetchRole = async () => {
      if (roleId) {
        const perms = await getPermissionByRole(roleId);
        const currentPerms = {};

        perms.forEach((p) => {
          const permName = p.permissao.nome;
          currentPerms[permName] = true;
        });

        setSignedUserPerms(currentPerms);
      }
    };
    fetchRole();
  }, [roleId, getPermissionByRole, setSignedUserPerms]);

  const handleLogin = async (formikValues) => {
    setLoading(true);
    await signIn(formikValues);
    setLoading(false);
  };

  if (authData && signedUserPerms) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container>
      <FormContainer>
        <div>
          <LogoImage alt="Logotipo do Mundo em Cores" src={logoHorizontal} />
        </div>
        <LoginMainText>Bem-vindo ao seu gerenciador de conteúdos</LoginMainText>
        <LoginSecondaryText>
          Informe os dados abaixo para acessar
        </LoginSecondaryText>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginSchema}
          onSubmit={(values) => handleLogin(values)}
        >
          {(formik) => (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <Spacer>
                <FormLabel>E-mail:</FormLabel>
                <Input
                  id="campo_email"
                  size="large"
                  type="email"
                  style={{ width: '100%', marginTop: '2px' }}
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue('email', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg name="email" />
              </Spacer>

              <Spacer>
                <FormLabel>Senha:</FormLabel>
                <Input.Password
                  id="campo_senha"
                  size="large"
                  style={{ width: '100%' }}
                  value={formik.values.password}
                  onChange={(e) =>
                    formik.setFieldValue('password', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
                <ErrorMsg name="password" />
              </Spacer>

              <Spacer style={{ margin: '0 auto' }}>
                <Button
                  type="primary"
                  id="botao_login"
                  onClick={formik.handleSubmit}
                  loading={loading}
                >
                  Entrar
                </Button>
              </Spacer>

              <Spacer style={{ margin: '10px auto 0' }}>
                <Button
                  type="link"
                  id="botao_recuperar_senha"
                  onClick={() => history.push('/esqueci-senha')}
                >
                  Esqueci minha senha
                </Button>
              </Spacer>
            </Form>
          )}
        </Formik>
      </FormContainer>
      <ColorsImage alt="cores do mundo em cores" src={loginBackground} />
    </Container>
  );
};

export default Login;
