import React, { useEffect, useState } from 'react';
import { Card, Table, Tooltip, Button, Space, Switch } from 'antd';
import {
  FileSearchOutlined,
  EditOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import getColumnSearchProps from '../../../components/shared/getColumnSearchProps';
import { useSubscription } from '../../../hooks/Subscription';
import moment from 'moment';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { SearchSubscriptions, ToggleSubscription } from './index';
import { SUBSCRIPTION_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';

const Subscription = ({ history }) => {
  const columns = [
    {
      title: 'Nome do plano',
      key: 'name',
      dataIndex: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'Vigência',
      key: 'email',
      dataIndex: 'vigencia',
      render: (text, record) =>
        record.vigenciaFinal
          ? `${moment(record.vigenciaInicial).format('L')} até ${moment(
              record.vigenciaFinal
            ).format('L')}`
          : `${moment(record.vigenciaInicial).format('L')}`,
    },
    {
      title: 'Periodicidade',
      key: 'role',
      dataIndex: 'periodicidade',
      sorter: (a, b) => a.periodicidade.localeCompare(b.periodicidade),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Valor',
      key: 'value',
      dataIndex: 'valor',
      sorter: (a, b) => a.valor - b.valor,
      sortDirections: ['ascend', 'descend'],
      render: (text, record) =>
        String(record.valor.toFixed(2)).replace('.', ','),
    },
    {
      title: 'Situação',
      key: 'situacao',
      dataIndex: 'situacao',
      render: (text, record) => (record.situacao ? 'Ativo' : 'Desativado'),
    },
    {
      title: 'Ações',
      key: 'actions',

      render: (text, record) => (
        <Space>
          {signedUserPerms[SUBSCRIPTION_PERMS.DELETE] ? (
            <Tooltip title={record.situacao ? 'Desativar' : 'Ativar'}>
              <Switch
                checked={record.situacao}
                onChange={() => {
                  setSelectedSub(record);
                  setToggleSubVisible(true);
                }}
              />
            </Tooltip>
          ) : null}

          {signedUserPerms[SUBSCRIPTION_PERMS.UPDATE] ? (
            <Tooltip title="Alterar">
              <Button
                icon={<EditOutlined />}
                shape="circle"
                onClick={() => {
                  history.push(`/dashboard/alterar-plano/${record.id}`);
                }}
              />
            </Tooltip>
          ) : null}

          <Tooltip title="Detalhes">
            <Button
              shape="circle"
              icon={<FileSearchOutlined />}
              onClick={() => {
                history.push(`/dashboard/plano/${record.id}`);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const { getSubscriptions, subsData } = useSubscription();
  const { subsArray } = subsData;
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [toggleSubVisible, setToggleSubVisible] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  useEffect(() => {
    const fetchSubs = async () => {
      setLoading(true);
      await getSubscriptions();
      setLoading(false);
    };
    fetchSubs();
  }, [getSubscriptions]);

  return (
    <Card
      title="Planos"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Space>
          {searchVisible ? (
            <Button
              danger
              id="botao_desativar_filtro"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(false)}
            >
              Desativar filtro
            </Button>
          ) : (
            <Button
              id="botao_filtrar"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(true)}
            >
              Filtrar Resultado
            </Button>
          )}

          {signedUserPerms[SUBSCRIPTION_PERMS.CREATE] ? (
            <Button
              id="botao_novo_plano"
              type="primary"
              onClick={() => {
                history.push('/dashboard/criar-plano');
              }}
            >
              Novo Plano
            </Button>
          ) : null}
        </Space>
      }
    >
      {searchVisible ? (
        <SearchSubscriptions loadingFunction={setLoading} />
      ) : null}

      {loading || subsArray === null ? (
        <LoadSpinner />
      ) : (
        <Table
          columns={columns}
          dataSource={subsArray}
          rowKey={(record) => record.id}
          locale={{
            emptyText: 'Nenhum dado encontrado.',
            triggerAsc: null,
            triggerDesc: null,
          }}
          scroll={{ x: true }}
        />
      )}

      {toggleSubVisible ? (
        <ToggleSubscription
          onCancel={() => setToggleSubVisible(false)}
          selected={selectedSub?.id}
          activate={!selectedSub?.situacao}
        />
      ) : null}
    </Card>
  );
};

export default Subscription;
