import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { AddSpecialist, SpecialistContent } from './index';
import { useSpecialist } from '../../../hooks/Specialist';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import GoBackButton from '../../../components/shared/GoBackButton';

const { TabPane } = Tabs;

const SpecialistDetail = (props) => {
  const { match, location, history } = props;
  const { idEspecialista } = match.params;

  const [loadingSpecialist, setLoadingSpecialist] = useState();
  const { getSpecialistById } = useSpecialist();

  useEffect(() => {
    const fetchSpecialist = async () => {
      setLoadingSpecialist(true);
      await getSpecialistById(idEspecialista);
      setLoadingSpecialist(false);
    };
    fetchSpecialist();
  }, [idEspecialista, getSpecialistById]);

  const handleTabChange = (tabName) => {
    history.push(`${match.url}/${tabName}`);
  };

  const handleActiveTab = () => {
    const key = location.pathname.split('/').pop();
    return key;
  };

  return (
    <>
      <GoBackButton to="/dashboard/especialistas" />
      <Card>
        <Tabs
          defaultActiveKey="especialista"
          onChange={handleTabChange}
          activeKey={handleActiveTab()}
        >
          <TabPane tab="Especialista" key="especialista" id="tab-especialista">
            {loadingSpecialist ? <LoadSpinner /> : <AddSpecialist {...props} />}
          </TabPane>
          <TabPane tab="Conteúdos" key="conteudos" id="tab-conteudos">
            {loadingSpecialist ? (
              <LoadSpinner />
            ) : (
              <SpecialistContent {...props} />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default SpecialistDetail;
