import React, { useState } from 'react';
import { Modal } from 'antd';
import { useRole } from '../../../hooks/Role';

const ToggleRole = ({ onCancel, selectedRole }) => {
  const [loading, setLoading] = useState(false);

  const { deactivateRole, activateRole } = useRole();

  const isActive = !selectedRole.dataDesativacao;

  const handleRequest = async () => {
    let result = null;
    setLoading(true);
    if (!isActive) {
      result = await activateRole(selectedRole.codigo);
    } else {
      result = await deactivateRole(selectedRole.codigo);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible
      title={
        isActive ? 'Desativar perfil de usuário' : 'Ativar perfil de usuário'
      }
      onCancel={onCancel}
      okText={isActive ? 'Desativar' : 'Ativar'}
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      {isActive ? (
        <p>Deseja realmente desativar este perfil de usuário?</p>
      ) : (
        <p>Deseja realmente ativar este perfil de usuário?</p>
      )}
    </Modal>
  );
};

export default ToggleRole;
