/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Tooltip, Space, Switch } from 'antd';
import { EditOutlined, FilterOutlined } from '@ant-design/icons';
import { AddRole, ToggleRole, SearchRole } from './index';
import { useRole } from '../../../hooks/Role';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import getColumnSearchProps from '../../../components/shared/getColumnSearchProps';
import { ROLE_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';

const Roles = () => {
  const columns = [
    {
      title: 'Nome do perfil',
      key: 'role',
      dataIndex: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('nome'),
    },

    {
      title: 'Descrição',
      key: 'description',
      dataIndex: 'descricao',
    },

    {
      title: 'Situação',
      key: 'status',
      render: (text, record) =>
        record.dataDesativacao ? 'Desativado' : 'Ativo',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space>
          {signedUserPerms[ROLE_PERMS.DELETE] ? (
            <Tooltip title={record.dataDesativacao ? 'Ativar' : 'Desativar'}>
              <Switch
                checked={!record.dataDesativacao}
                onChange={() => {
                  setSelectedRole(record);
                  setToggleRoleVisible(true);
                }}
              />
            </Tooltip>
          ) : null}

          {signedUserPerms[ROLE_PERMS.UPDATE] ? (
            <Tooltip title="Alterar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedRole(record);
                  setAddRoleVisible(true);
                }}
              />
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  const [toggleRoleVisible, setToggleRoleVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [addRoleVisible, setAddRoleVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const { getRoles, rolesData } = useRole();
  const { rolesArray } = rolesData;

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      await getRoles();
      setLoading(false);
    };
    fetchRoles();
  }, [getRoles]);

  return (
    <Card
      title="Perfis de Usuário"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Space>
          {searchVisible ? (
            <Button
              danger
              id="botao_desativar_filtro"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(false)}
            >
              Desativar filtro
            </Button>
          ) : (
            <Button
              id="botao_filtrar"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(true)}
            >
              Filtrar Resultado
            </Button>
          )}

          {signedUserPerms[ROLE_PERMS.CREATE] ? (
            <Button
              id="botao_novo_perfil"
              type="primary"
              onClick={() => {
                setSelectedRole(null);
                setAddRoleVisible(true);
              }}
            >
              Novo Perfil
            </Button>
          ) : null}
        </Space>
      }
    >
      {searchVisible ? <SearchRole loadingFunction={setLoading} /> : null}

      {loading || rolesArray === null ? (
        <LoadSpinner />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={rolesArray}
            rowKey={(record) => record.codigo}
            locale={{
              emptyText: 'Nenhum dado encontrado.',
              triggerAsc: null,
              triggerDesc: null,
            }}
          />
        </>
      )}

      {addRoleVisible ? (
        <AddRole
          visible
          onCancel={() => setAddRoleVisible(false)}
          selectedRole={selectedRole}
        />
      ) : null}

      {toggleRoleVisible ? (
        <ToggleRole
          selectedRole={selectedRole}
          onCancel={() => setToggleRoleVisible(false)}
        />
      ) : null}
    </Card>
  );
};

export default Roles;
