import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { getUrlQuery } from './util';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const getUserById = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Usuario/Backoffice/${id}`);

      return response.data.result;
    } catch (error) {
      message.error('Erro ao buscar dados do usuário!!');
    }
  }, []);

  const getUsers = useCallback(async (params = {}) => {
    const newParams = {
      Nome: params.name || undefined,
      Email: params.email || undefined,
      Telefone: params.phoneNumber || undefined,
      PerfilId: params.role || undefined,
    };

    try {
      const response = await axios.get(
        `/Usuario/Backoffice?${getUrlQuery(newParams)}`
      );

      const result = response.data.listResult ?? response.data.result;

      setUserList(result);
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissao para visualizar usuários.');
      } else {
        message.error('Erro ao buscar usuários!');
      }
    }
  }, []);

  const createUser = useCallback(
    async ({ name, email, phoneNumber, role }) => {
      try {
        const postBody = {
          nome: name,
          email,
          userName: email,
          telefone: phoneNumber || undefined,
          perfilId: role,
        };

        await axios.post(`/Usuario/Backoffice`, postBody);
        await getUsers();

        message.success('Usuário cadastrado com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar usuários.');
        } else if (error.response.data) {
          message.error(error.response.data[0].description);
        } else {
          message.error('Erro ao cadastrar usuário!');
        }

        return false;
      }
    },
    [getUsers]
  );

  const updateUser = useCallback(
    async ({ name, email, phoneNumber, role, id }) => {
      try {
        const postBody = {
          nome: name,
          email,
          userName: email,
          telefone: phoneNumber || undefined,
          perfilId: role,
          seguindo: false,
          tipoUsuario: 1,
        };

        await axios.put(`/Usuario/Backoffice/${id}`, postBody);
        await getUsers();

        message.success('Usuário alterado com sucesso!');

        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar usuários.');
        } else if (error.response?.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar usuário!');
        }

        return false;
      }
    },
    [getUsers]
  );

  const deactivateUser = useCallback(
    async (id) => {
      try {
        await axios.delete(`/Usuario/${id}`);
        await getUsers();

        message.success('Usuário desativado com sucesso!');
        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para desativar usuários.');
        } else if (error.response?.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao desativar usuário!');
        }

        return false;
      }
    },
    [getUsers]
  );

  const activateUser = useCallback(
    async (id) => {
      try {
        await axios.put(`/Usuario/${id}/Reativar`);
        await getUsers();

        message.success('Usuário ativado com sucesso!');
        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para ativar usuários.');
        } else if (error.response?.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao ativar usuário!');
        }

        return false;
      }
    },
    [getUsers]
  );

  return (
    <UserContext.Provider
      value={{
        usersData: { userList },
        getUserById,
        getUsers,
        createUser,
        updateUser,
        deactivateUser,
        activateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
