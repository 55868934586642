import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer, ErrorMsg } from '../../../../components/Form/index';
import { Modal, Input, Button, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useSubscription } from '../../../../hooks/Subscription';
import * as yup from 'yup';

const formSchema = yup.object().shape({
  promName: yup.string().required('Este campo é obrigatório.'),
  promInitialDate: yup.date().required('Este campo é obrigatório.'),
  promFinalDate: yup.date().required('Este campo é obrigatório.'),
  promValue: yup
    .number()
    .typeError('Este campo é obrigatório.')
    .min(0, 'O valor deve ser maior ou igual a zero.')
    .required('Este campo é obrigatório.'),
});

const dateFormat = 'DD/MM/YYYY';

const AddSpecial = ({
  visible,
  onCancel,
  update,
  idPlano,
  selected,
  specialSubs,
}) => {
  const [loading, setLoading] = useState(false);
  const { addSpecialSub, updateSpecialSub } = useSubscription();

  const handleRequest = async (formValues, formikBag) => {
    const { promInitialDate, promFinalDate } = formValues;

    const isDateValid = validateDates(promInitialDate, promFinalDate);
    if (!isDateValid.success) {
      // todo
      if (isDateValid.field === 'initial') {
        formikBag.setFieldError(
          'promInitialDate',
          'Existe promoção cadastrada com período conflitante'
        );
      } else if (isDateValid.field === 'final') {
        formikBag.setFieldError(
          'promFinalDate',
          'Existe promoção cadastrada com período conflitante'
        );
      } else {
        formikBag.setFieldError(
          'promInitialDate',
          'Existe promoção cadastrada com período conflitante'
        );
        formikBag.setFieldError(
          'promFinalDate',
          'Existe promoção cadastrada com período conflitante'
        );
      }
      return;
    }

    let result = null;
    setLoading(true);
    if (update) {
      result = await updateSpecialSub({
        ...formValues,
        idPromo: selected.idPromo,
        idPlano: selected.idPlano,
      });
    } else {
      result = await addSpecialSub({ ...formValues, idPlano });
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };

  const validateDates = (initial, final) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < specialSubs.length; i++) {
      if (specialSubs[i].idPromo !== selected?.idPromo) {
        let { vigenciaInicial, vigencialFinal } = specialSubs[i];

        vigenciaInicial = moment(vigenciaInicial);
        vigencialFinal = moment(vigencialFinal);

        if (initial.isBetween(vigenciaInicial, vigencialFinal)) {
          return { success: false, field: 'initial' };
        }

        if (final.isBetween(vigenciaInicial, vigencialFinal)) {
          return { success: false, field: 'final' };
        }

        if (vigenciaInicial.isBetween(initial, final)) {
          return { success: false, field: 'both' };
        }

        if (vigencialFinal.isBetween(initial, final)) {
          return { success: false, field: 'both' };
        }

        if (
          initial.isSameOrAfter(vigenciaInicial) &&
          final.isSameOrBefore(vigencialFinal)
        ) {
          return { success: false, field: 'both' };
        }
      }
    }
    return { success: true };
  };

  return (
    <Modal
      visible={visible}
      title={update ? 'Alterar Promoção' : 'Criar Promoção'}
      onCancel={onCancel}
      footer={null}
    >
      <Formik
        initialValues={
          update
            ? {
                promName: selected.nome,
                promInitialDate: moment(selected.vigenciaInicial),
                promFinalDate: moment(selected.vigencialFinal),
                promValue: selected.valor,
              }
            : {
                promName: '',
                promInitialDate: '',
                promFinalDate: '',
                promValue: 0,
              }
        }
        onSubmit={(values, formikBag) => handleRequest(values, formikBag)}
        validationSchema={formSchema}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <>
              <Spacer>
                <FormLabel>Nome da promoção:*</FormLabel>
                <Input
                  id="campo_nome_promocao"
                  value={formik.values.promName}
                  onChange={(e) =>
                    formik.setFieldValue('promName', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  maxLength={90}
                />
                <ErrorMsg name="promName" />
              </Spacer>

              <Spacer>
                <FormLabel>Vigência inicial:*</FormLabel>
                <DatePicker
                  id="campo_promo_data_inicio"
                  locale={locale}
                  onBlur={formik.handleBlur}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  value={formik.values.promInitialDate}
                  disabled={
                    selected && formik.values.promInitialDate < moment()
                  }
                  onChange={(e) => {
                    formik.setFieldValue('promInitialDate', e);
                    formik.setFieldValue('promFinalDate', '');
                  }}
                />
                <ErrorMsg name="promInitialDate" />
              </Spacer>

              <Spacer>
                <FormLabel>Vigência final:*</FormLabel>
                <DatePicker
                  id="campo_promo_data_fim"
                  locale={locale}
                  onBlur={formik.handleBlur}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  value={formik.values.promFinalDate}
                  onChange={(e) => formik.setFieldValue('promFinalDate', e)}
                  disabledDate={(current) =>
                    current < formik.values.promInitialDate
                  }
                  disabled={selected && formik.values.promFinalDate < moment()}
                />
                <ErrorMsg name="promFinalDate" />
              </Spacer>

              <Spacer>
                <FormLabel>Valor (R$):*</FormLabel>
                <InputNumber
                  id="campo_promo_valor"
                  onChange={(value) => formik.setFieldValue('promValue', value)}
                  value={formik.values.promValue}
                  onBlur={formik.handleBlur}
                  decimalSeparator=","
                  precision={2}
                  step={0.01}
                  style={{ width: '100%' }}
                />
                <ErrorMsg name="promValue" />
              </Spacer>

              <Spacer style={{ marginLeft: 'auto' }}>
                <Button
                  type="primary"
                  id="botao_salvar"
                  onClick={formik.handleSubmit}
                  loading={loading}
                >
                  Salvar
                </Button>
              </Spacer>
            </>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddSpecial;
