import React from 'react';
import { Layout, Card } from 'antd';
import PrivateRoute from '../../routes/PrivateRoute';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import GoBackButton from '../../components/shared/GoBackButton';
import LayoutHeader from '../../components/Dashboard/LayoutHeader/LayoutHeader';

import { Users } from './Users/index';
import { Roles } from './Roles/index';
import { UserData } from './UserData/index';
import { AddPermission } from './Permission/index';
import { AddSubscription, Subscription, SubDetail } from './Subscription/index';
import {
  AddSpecialist,
  Specialist,
  SpecialistDetail,
} from './Specialist/index';
import {
  AddMedia,
  Content as ContentTable,
  AddContent,
  ContentDetail,
} from './Content/index';
import { AddHighlight } from './HighlightsContent/index';
import Welcome from './Welcome';

const { Content } = Layout;

const Dashboard = ({ match }) => {
  return (
    <Layout style={{ height: '100%' }}>
      <LayoutHeader />
      <Layout>
        <Sidebar />
        <Content style={{ margin: '24px 16px 0' }}>
          <PrivateRoute exact path="/dashboard" component={Welcome} />

          <PrivateRoute
            exact
            path={`${match.path}/usuarios`}
            component={Users}
          />

          <PrivateRoute exact path={`${match.path}/perfis`} component={Roles} />

          <PrivateRoute
            exact
            path={`${match.path}/meus-dados`}
            component={UserData}
          />

          <PrivateRoute
            exact
            path={`${match.path}/permissoes`}
            component={AddPermission}
          />

          <PrivateRoute
            exact
            path={`${match.path}/criar-plano`}
            component={AddSubscription}
          />
          <PrivateRoute
            exact
            path={`${match.path}/planos`}
            component={Subscription}
          />

          <PrivateRoute
            exact
            path={`${match.path}/plano/:idPlano`}
            component={SubDetail}
          />

          <PrivateRoute
            exact
            path={`${match.path}/alterar-plano/:idPlano`}
            component={AddSubscription}
          />

          <PrivateRoute
            exact
            path={`${match.path}/especialistas`}
            component={Specialist}
          />

          <PrivateRoute
            exact
            path={`${match.path}/criar-especialista`}
            render={(props) => (
              <>
                <GoBackButton to="/dashboard/especialistas" />
                <Card title="Cadastrar Especialista">
                  <AddSpecialist {...props} />
                </Card>
              </>
            )}
          />

          <PrivateRoute
            path={`${match.path}/alterar-especialista/:idEspecialista`}
            component={SpecialistDetail}
          />

          <PrivateRoute
            exact
            path={`${match.path}/criar-conteudo`}
            component={AddContent}
          />

          <PrivateRoute
            exact
            path={`${match.path}/alterar-conteudo/:idConteudo`}
            component={AddContent}
          />

          <PrivateRoute
            exact
            path={`${match.path}/criar-media`}
            component={AddMedia}
          />

          <PrivateRoute
            exact
            path={`${match.path}/tabela-conteudos`}
            component={ContentTable}
          />

          <PrivateRoute
            path={`${match.path}/conteudo/:idConteudo`}
            component={ContentDetail}
          />

          <PrivateRoute
            exact
            path={`${match.path}/conteudo-destaques`}
            component={AddHighlight}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
