import React, { useState, useEffect } from 'react';
import {
  VideoCameraOutlined,
  CustomerServiceOutlined,
  FileOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Input, Modal, Upload, Button, Divider } from 'antd';
import { Formik, Form } from 'formik';
import {
  FormLabel,
  ErrorMsg,
  Spacer,
} from '../../../../../components/Form/index';
import * as yup from 'yup';
import { useTag } from '../../../../../hooks/Tag';
import loadAudio from '../../../../../components/shared/loadAudio';
import { useLesson } from '../../../../../hooks/Lesson';

const schema = yup.object().shape({
  title: yup.string().required('Este campo é obrigatório.'),
});

const AddLesson = ({ onCancel, section, selected }) => {
  const [loading, setLoading] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [error, setError] = useState('');
  const { getAllTags } = useTag();
  const { createLesson, updateLesson, deleteLessonFile } = useLesson();

  useEffect(() => {
    const fetchTags = async () => {
      await getAllTags();
    };
    fetchTags();
  }, [getAllTags]);

  const handleAudioUpload = async (info, formik) => {
    const { originFileObj } = info.file;
    const audio = await loadAudio(originFileObj);
    const duration = Math.floor(audio.duration);

    const audioObj = {
      name: originFileObj.name,
      file: originFileObj,
      duration,
    };
    setError('');
    formik.setFieldValue('audio', audioObj);
  };

  const handleFileUpload = (info, formik) => {
    const { fileList } = info;
    console.log(info);

    const cacheArray = formik.values.newFiles.map((f) => f.name);
    const filesArray = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < fileList.length; i++) {
      const currentFile = fileList[i];
      if (!cacheArray.includes(currentFile.name)) {
        cacheArray.push(currentFile.name);
        const file = {
          name: currentFile.name,
          file: currentFile.originFileObj,
        };
        filesArray.push(file);
      }
    }

    const newFilesArray = formik.values.newFiles.concat(filesArray);
    setError('');
    formik.setFieldValue('newFiles', newFilesArray);
  };

  const handleSubmit = async (values) => {
    let result = null;

    if (
      !values.video &&
      !values.audio &&
      !values.files.length &&
      !values.newFiles.length
    ) {
      setError('Insira um áudio, PDF ou link de vídeo!');
      return;
    }

    const body = {
      ...values,
      audio: { ...values.audio },
      file: { ...values.file },
    };

    if (typeof body.file?.file === 'string') {
      delete body.file.file;
    }

    if (typeof body.audio?.file === 'string') {
      delete body.audio.file;
    }

    setLoading(true);

    await Promise.all(filesToDelete.map((fileId) => deleteLessonFile(fileId)));

    if (selected) {
      result = await updateLesson({
        ...body,
        sectionId: selected.idModulo,
        contentId: section.idConteudo,
        id: selected.id,
      });
    } else {
      result = await createLesson({
        ...values,
        sectionId: section.idModulo,
        contentId: section.idConteudo,
      });
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };

  const handleDeleteFile = (fileId, formik) => {
    const filtered = formik.values.files.filter((file) => {
      if (file.id === fileId) {
        return false;
      }
      return true;
    });

    formik.setFieldValue('files', filtered);

    console.log('fileId', fileId);
    setFilesToDelete((promises) => promises.concat(fileId));
  };

  const handleDeleteNewFile = (fileName, formik) => {
    const filtered = formik.values.newFiles.filter((file) => {
      if (file.name === fileName) {
        return false;
      }
      return true;
    });

    formik.setFieldValue('newFiles', filtered);
  };

  const formikInitialValues = selected
    ? {
        title: selected.titulo,
        video: selected.link,
        audio: selected.nomeAudio
          ? { name: selected.nomeAudio, file: selected.urlAudio }
          : null,
        files: selected.arquivos?.map((ar) => ({
          id: ar.id,
          name: ar.nomeArquivo,
          file: ar.urlArquivo,
        })),
        newFiles: [],
      }
    : {
        title: '',
        video: '',
        files: [],
        audio: null,
        newFiles: [],
      };

  return (
    <Modal visible title="Adicionar aula" footer={null} onCancel={onCancel}>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel>Título:</FormLabel>
            <Input
              id="campo_titulo"
              name="title"
              onChange={(e) => formik.setFieldValue('title', e.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              maxLength={500}
            />
            <ErrorMsg name="title" />

            <Divider style={{ margin: '18px 0' }} />

            <FormLabel>Link do vídeo:</FormLabel>
            <Input
              id="campo_video"
              name="video"
              prefix={<VideoCameraOutlined />}
              onChange={(e) => {
                setError('');
                formik.setFieldValue('video', e.target.value);
              }}
              value={formik.values.video}
              maxLength={500}
            />

            <div style={{ display: 'flex', width: '100%' }}>
              <Upload
                className="upload-media"
                showUploadList={false}
                onChange={(info) => handleAudioUpload(info, formik)}
                accept="audio/*"
              >
                <Button
                  icon={<CustomerServiceOutlined />}
                  style={{ width: '100%' }}
                  disabled={formik.values.audio?.file}
                >
                  Áudio
                </Button>
              </Upload>

              <Upload
                className="upload-media"
                showUploadList={false}
                onChange={(info) => handleFileUpload(info, formik)}
                accept=".pdf"
                multiple
              >
                <Button icon={<FileOutlined />} style={{ width: '100%' }}>
                  Arquivo
                </Button>
              </Upload>
            </div>

            <Spacer>
              {formik.values.audio?.file ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 18px',
                    margin: '5px 0',
                  }}
                >
                  <div>{formik.values.audio.name}</div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => formik.setFieldValue('audio', null)}
                  />
                </div>
              ) : null}

              {formik.values.files.map((file) => (
                <div
                  key={file.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 18px',
                    margin: '5px 0',
                  }}
                >
                  <div>{file.name}</div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteFile(file.id, formik)}
                  />
                </div>
              ))}

              {error ? (
                <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
              ) : null}

              {formik.values.newFiles.map((file) => (
                <div
                  key={file.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 18px',
                    margin: '5px 0',
                  }}
                >
                  <div>{file.name}</div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteNewFile(file.name, formik)}
                  />
                </div>
              ))}
            </Spacer>

            <Divider style={{ margin: '18px 0' }} />

            <Spacer style={{ marginLeft: 'auto' }}>
              <Button
                id="botao_salvar"
                type="primary"
                onClick={formik.handleSubmit}
                loading={loading}
              >
                Salvar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLesson;
