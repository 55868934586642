import React from 'react';
import { Modal } from 'antd';
import { useAuth } from '../../hooks/Auth';

const LogoutModal = ({ visible, onCancel }) => {
  const { logout } = useAuth();

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okText="Sair"
      cancelText="Cancelar"
      title="Fazer logout"
      onOk={logout}
    >
      <p>Deseja realmente sair do sistema?</p>
    </Modal>
  );
};

export default LogoutModal;
