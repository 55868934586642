import * as yup from 'yup';

export const addSubscriptionSchema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório!'),
  initialDate: yup.date().required('A data inicial é obrigatória!').nullable(),
  timeSpan: yup.string().required('A periodicidade é obrigatória!'),
  description: yup.string().required('A descrição é obrigatória!'),
  price: yup
    .number()
    .typeError('Este campo é obrigatório.')
    .min(0, 'O valor deve ser maior ou igual a zero!')
    .required('Este campo é obrigatório.'),
});
