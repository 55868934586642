import React, { useEffect, useState } from 'react';
import { Card, Table, Space, Tooltip, Button, Switch } from 'antd';
import { EditOutlined, FilterOutlined } from '@ant-design/icons';
import getColumnSearchProps from '../../../components/shared/getColumnSearchProps';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { useContent } from '../../../hooks/Content';
import { ToggleContent, SearchContent } from './index';
import { CONTENT_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';

const Content = ({ history }) => {
  const columns = [
    {
      title: 'Título',
      key: 'title',
      dataIndex: 'titulo',
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('titulo'),
    },
    {
      title: 'Assunto',
      key: 'subject',
      dataIndex: 'assunto',
      sorter: (a, b) => a.assunto.localeCompare(b.assunto),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('assunto'),
    },
    {
      title: 'Especialista',
      key: 'specialist',
      dataIndex: ['especialistas', 'nome'],
      sorter: (a, b) =>
        a.especialistas.nome.localeCompare(b.especialistas.nome),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'categoria',
      sorter: (a, b) => a.categoria.localeCompare(b.categoria),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('categoria'),
    },
    {
      title: 'Situação',
      key: 'situacao',
      dataIndex: 'situacao',
      render: (text, record) => (record.situacao ? 'Ativo' : 'Desativado'),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space>
          {signedUserPerms[CONTENT_PERMS.DELETE] ? (
            <Tooltip title={!record.situacao ? 'Ativar' : 'Desativar'}>
              <Switch
                checked={record.situacao}
                onChange={() => {
                  setSelectedContent(record);
                  setToggleContentVisible(true);
                }}
              />
            </Tooltip>
          ) : null}

          {signedUserPerms[CONTENT_PERMS.UPDATE] ? (
            <Tooltip title="Alterar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() =>
                  history.push(
                    `/dashboard/conteudo/${record.idConteudo}/info-gerais`
                  )
                }
              />
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [toggleContentVisible, setToggleContentVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const { contentData, getAllContent } = useContent();
  const { contentArray } = contentData;

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      await getAllContent();
      setLoading(false);
    };
    fetchContent();
  }, [getAllContent]);

  return (
    <Card
      title="Conteúdos"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Space>
          {searchVisible ? (
            <Button
              danger
              id="botao_desativar_filtro"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(false)}
            >
              Desativar filtro
            </Button>
          ) : (
            <Button
              id="botao_filtrar"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(true)}
            >
              Filtrar Resultado
            </Button>
          )}

          {signedUserPerms[CONTENT_PERMS.CREATE] ? (
            <Button
              id="botao_novo_conteudo"
              type="primary"
              onClick={() => {
                history.push('/dashboard/criar-conteudo');
              }}
            >
              Novo Conteúdo
            </Button>
          ) : null}
        </Space>
      }
    >
      {searchVisible ? <SearchContent loadingFunction={setLoading} /> : null}

      {loading || contentArray === null ? (
        <LoadSpinner />
      ) : (
        <Table
          columns={columns}
          dataSource={contentArray}
          rowKey={(record) => record.idConteudo}
          locale={{
            emptyText: 'Nenhum dado encontrado.',
            triggerAsc: null,
            triggerDesc: null,
          }}
          scroll={{ x: true }}
        />
      )}

      {toggleContentVisible ? (
        <ToggleContent
          selected={selectedContent}
          onCancel={() => setToggleContentVisible(false)}
        />
      ) : null}
    </Card>
  );
};

export default Content;
