import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Digite um email válido!')
    .required('Este campo é obrigatório!'),
  password: yup.string().required('Este campo é obrigatório!'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Digite um email válido!')
    .required('Este campo é obrigatório!'),
});
