import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { useContent } from '../../../hooks/Content';
import { Table, Space, Tooltip, Button } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { useSpecialist } from '../../../hooks/Specialist';

const SpecialistContent = ({ history }) => {
  const columns = [
    {
      title: 'Título',
      key: 'title',
      dataIndex: 'titulo',
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
      sortDirections: ['ascend', 'descend'],
    },

    {
      title: 'Assunto',
      key: 'subject',
      dataIndex: 'assunto',
      sorter: (a, b) => a.assunto.localeCompare(b.assunto),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'categoria',
      sorter: (a, b) => a.categoria.localeCompare(b.categoria),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Situação',
      key: 'situacao',
      dataIndex: 'situacao',
      render: (text, record) => (record.situacao ? 'Ativo' : 'Desativado'),
    },
    {
      title: 'Ações',
      key: 'actions',

      render: (text, record) => (
        <Space>
          <Tooltip title="Detalhes">
            <Button
              shape="circle"
              icon={<FileSearchOutlined />}
              onClick={() => {
                history.push(
                  `/dashboard/conteudo/${record.idConteudo}/info-gerais`
                );
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const { specialistsData } = useSpecialist();
  const { currentSpecialist } = specialistsData;

  const [loadingContent, setLoadingContent] = useState(false);
  const { getAllContent, contentData } = useContent();
  const { contentArray } = contentData;
  const current = currentSpecialist?.nome;

  useEffect(() => {
    const fetchContent = async () => {
      setLoadingContent(true);
      await getAllContent({ specialist: current });
      setLoadingContent(false);
    };
    fetchContent();
  }, [getAllContent, current]);

  if (loadingContent) {
    return <LoadSpinner />;
  }

  return (
    <Table
      dataSource={contentArray}
      columns={columns}
      rowKey={(record) => record.idConteudo}
      locale={{
        emptyText: 'Nenhum dado encontrado.',
        triggerAsc: null,
        triggerDesc: null,
      }}
      scroll={{ x: true }}
    />
  );
};

export default SpecialistContent;
