import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes/Routes';
import { AuthProvider } from './hooks/Auth';
import { UserProvider } from './hooks/User';
import { RoleProvider } from './hooks/Role';
import { PermProvider } from './hooks/Permission';
import { SubscriptionProvider } from './hooks/Subscription';
import { SpecialistProvider } from './hooks/Specialist';
import { ContentProvider } from './hooks/Content';
import { TagProvider } from './hooks/Tag';
import { LessonProvider } from './hooks/Lesson';

function App() {
  return (
    <PermProvider>
      <AuthProvider>
        <UserProvider>
          <RoleProvider>
            <BrowserRouter>
              <SubscriptionProvider>
                <SpecialistProvider>
                  <ContentProvider>
                    <LessonProvider>
                      <TagProvider>
                        <Routes />
                      </TagProvider>
                    </LessonProvider>
                  </ContentProvider>
                </SpecialistProvider>
              </SubscriptionProvider>
            </BrowserRouter>
          </RoleProvider>
        </UserProvider>
      </AuthProvider>
    </PermProvider>
  );
}

export default App;
