import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { useUser } from '../../../hooks/User';
import { useAuth } from '../../../hooks/Auth';

const ToggleUser = ({ visible, onCancel, selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const { authData } = useAuth();

  const isActive = Boolean(selectedUser.dataDesativacao);

  const { deactivateUser, activateUser } = useUser();
  const handleRequest = async () => {
    let result = null;

    if (authData.idUser === selectedUser.codigo) {
      message.error('Não possível desativar sua própria conta!');
      return;
    }

    setLoading(true);
    if (isActive) {
      result = await activateUser(selectedUser.codigo);
    } else {
      result = await deactivateUser(selectedUser.codigo);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible={visible}
      title={isActive ? 'Ativar usuário' : 'Desativar usuário'}
      onCancel={onCancel}
      okText={isActive ? 'Ativar' : 'Desativar'}
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      {isActive ? (
        <p>Deseja realmente ativar este usuário?</p>
      ) : (
        <p>Deseja realmente desativar este usuário?</p>
      )}
    </Modal>
  );
};

export default ToggleUser;
