import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'http://sistemas.globalsys.com.br:2012/api',
  baseURL: 'http://sistemas.globalsys.com.br:4012/api',
  // baseURL: 'http://sistemas.globalsys.com.br:6012/api',
});

export default axiosInstance;

// 2012 - DEV
// 4012 - QA
// 6012 - PROD
