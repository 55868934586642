import React, { useState } from 'react';
import { Modal } from 'antd';
import { useContent } from '../../../../../hooks/Content';

const DeactivateSection = ({ onCancel, section }) => {
  const [loading, setLoading] = useState(false);

  const { updateContentSection } = useContent();

  const handleRequest = async () => {
    setLoading(true);
    const result = await updateContentSection({
      id: section.idModulo,
      contentId: section.idConteudo,
      status: false,
    });
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible
      title="Excluir Módulo"
      onCancel={onCancel}
      okText="Excluir"
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      <p>Deseja realmente excluir este módulo?</p>
    </Modal>
  );
};

export default DeactivateSection;
