import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Input, Select, Upload, Button, InputNumber, DatePicker } from 'antd';
import { Spacer, FormLabel, ErrorMsg } from '../../../components/Form/index';
import { Formik, Form } from 'formik';
import { addContentSchema } from './AddContent/formValidation';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import { CONTENT_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';
import { useSpecialist } from '../../../hooks/Specialist';
import { useContent } from '../../../hooks/Content';
import getBase64 from '../../../components/shared/getBase64';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { DisplayTags, AddTag } from '../../../components/Dashboard/Tags';
import InputMask from 'react-input-mask';

const dateFormat = 'DD/MM/YYYY';

const subjects = [
  'Amamentação',
  'Aprendizado',
  'Autonomia da criança',
  'Birra',
  'Brincadeiras',
  'Conversando com a criança',
  'Comportamento da criança',
  'Crianças',
  'Exemplo dos pais',
  'Família',
  'Limites',
  'Recém nascido',
  'Rotina da criança',
  'Sentimentos da criança',
];

const AddContentForm = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingSpecialist, setLoadingSpecialist] = useState(false);
  const [manageTagsVisible, setManageTagsVisible] = useState(false);
  const [loadingTagRequest, setLoadingTagRequest] = useState(false);
  const { getAllSpecialists } = useSpecialist();
  const {
    createContent,
    getContentById,
    updateContent,
    updateContentTags,
  } = useContent();
  const { idConteudo } = match.params;

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const [currentContent, setCurrentContent] = useState(null);
  const [specialistArray, setSpecialistArray] = useState([]);

  const getCurrentContent = useCallback(async () => {
    if (idConteudo) {
      setLoadingFetch(true);
      const result = await getContentById(idConteudo);
      setCurrentContent(result);
      setLoadingFetch(false);
    }
  }, [getContentById, idConteudo]);

  useEffect(() => {
    getCurrentContent();
  }, [getCurrentContent]);

  useEffect(() => {
    const initialFetchSpecialist = async () => {
      setLoadingSpecialist(true);
      const result = await getAllSpecialists({ name: '', status: true });
      setSpecialistArray(result);
      setLoadingSpecialist(false);
    };
    initialFetchSpecialist();
  }, [getAllSpecialists]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Enviar imagem</div>
    </div>
  );

  const handleTags = async (tags, contentId) => {
    const bodyArray = tags.map((t) => ({
      idTag: t.idTag,
      idConteudo: contentId,
    }));

    await updateContentTags({
      bodyArray,
      contentId,
    });
    setManageTagsVisible(false);
    await getCurrentContent();
    setLoadingTagRequest(false);
  };

  const handleDeleteTag = async (newTagsArray) => {
    setLoadingTagRequest(true);
    await updateContentTags({
      bodyArray: newTagsArray,
      contentId: idConteudo,
    });
    await getCurrentContent();
    setLoadingTagRequest(false);
  };

  const handleImage = async (info, formik, field) => {
    setLoading(true);
    const result = await getBase64(info.file.originFileObj);
    formik.setFieldValue(field, result);
    setLoading(false);
  };

  const fetchSpecialist = debounce(async (value) => {
    setLoadingSpecialist(true);
    await getAllSpecialists({ name: value, status: true });
    setLoadingSpecialist(false);
  }, 300);

  const handleRequest = async (formikValues, formikBag) => {
    if (formikValues.duration.includes('_')) {
      formikBag.setFieldError('duration', 'Este campo é obrigatório');
      return;
    }
    if (formikValues.aditionalPromoValue) {
      if (!formikValues.initialDate) {
        formikBag.setFieldError(
          'initialDate',
          'Informe a vigência da promoção'
        );
        return;
      }

      if (!formikValues.finalDate) {
        formikBag.setFieldError('finalDate', 'Informe a vigência da promoção');
        return;
      }
    }

    let specialist = {};

    if (
      idConteudo &&
      formikValues.specialist === currentContent.especialistas.nome
    ) {
      specialist.idEspecialista = currentContent.especialistas.idEspecialista;
    } else {
      specialist = specialistArray.filter(
        (s) => s.nome === formikValues.specialist
      )[0];
    }

    let result = null;

    setLoadingRequest(true);
    if (idConteudo) {
      result = await updateContent({
        ...formikValues,
        specialist: specialist.idEspecialista,
        id: idConteudo,
      });
    } else {
      result = await createContent({
        ...formikValues,
        specialist: specialist.idEspecialista,
      });
    }
    setLoadingRequest(false);

    if (idConteudo) {
      history.push(`/dashboard/conteudo/${idConteudo}/info-gerais`);
    } else {
      history.push(`/dashboard/conteudo/${result}/info-gerais`);
    }
  };

  if ((idConteudo && loadingFetch) || (idConteudo && currentContent === null)) {
    return <LoadSpinner />;
  }

  return (
    <Formik
      initialValues={
        idConteudo
          ? {
              contentPhoto: `${
                currentContent?.fotoCapa
              }&?=${new Date().getTime()}`,
              detailPhoto: `${
                currentContent?.fotoDetalhe
              }&?=${new Date().getTime()}`,
              title: currentContent?.titulo,
              specialist: currentContent?.especialistas.nome,
              audience: currentContent?.publicoAlvo,
              hasCertificate: currentContent?.certificado,
              aditional: currentContent?.cobrancaAdicional,
              allowOffline: currentContent?.exibeOffline,
              aditionalValue: currentContent?.valorCobranca,
              aditionalPromoValue: currentContent?.valorPromo,
              category: currentContent?.categoria,
              subject: currentContent?.assunto,
              initialDate: moment(currentContent?.vigenciaInicial),
              finalDate: moment(currentContent?.vingenciaFinal),
              description: currentContent?.descricao,
              objective: currentContent?.objetivo,
              duration: currentContent?.duracao,
              status: currentContent?.situacao,
            }
          : {
              contentPhoto: '',
              detailPhoto: '',
              title: '',
              specialist: '',
              audience: '',
              hasCertificate: '',
              aditional: '',
              allowOffline: '',
              aditionalValue: 0,
              aditionalPromoValue: 0,
              category: '',
              subject: '',
              initialDate: '',
              finalDate: '',
              description: '',
              objective: '',
              duration: '',
              status: false,
            }
      }
      validationSchema={addContentSchema}
      onSubmit={handleRequest}
    >
      {(formik) => (
        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <ImageContainer>
            <div>
              <FormLabel>Foto do curso:*</FormLabel>
              <Upload
                listType="picture-card"
                showUploadList={false}
                onChange={(info) => handleImage(info, formik, 'contentPhoto')}
                accept="image/*"
              >
                {formik.values.contentPhoto ? (
                  <img
                    src={formik.values.contentPhoto}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <ErrorMsg name="contentPhoto" />
            </div>

            <div>
              <FormLabel>Foto de detalhe:*</FormLabel>
              <Upload
                listType="picture-card"
                showUploadList={false}
                onChange={(info) => handleImage(info, formik, 'detailPhoto')}
                accept="image/*"
              >
                {formik.values.detailPhoto ? (
                  <img
                    src={formik.values.detailPhoto}
                    alt="avatar"
                    style={{ width: '100%' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <ErrorMsg name="detailPhoto" />
            </div>
          </ImageContainer>

          <FormLabel>Título:*</FormLabel>
          <Input
            id="campo_titulo"
            name="title"
            style={{ width: '100%' }}
            value={formik.values.title}
            onChange={(e) => formik.setFieldValue('title', e.target.value)}
            onBlur={formik.handleBlur}
            maxLength={90}
          />
          <ErrorMsg name="title" />

          <HorizontalContainer>
            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Categoria:*</FormLabel>
              <Select
                id="campo_categoria"
                style={{ width: '100%' }}
                onBlur={formik.handleBlur}
                value={formik.values.category}
                onChange={(value) => formik.setFieldValue('category', value)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value="Curso">Curso</Select.Option>
                <Select.Option value="Série">Série</Select.Option>
                <Select.Option value="Playlist">Playlist</Select.Option>
                <Select.Option value="Programa">Programa</Select.Option>
              </Select>
              <ErrorMsg name="category" />
            </Spacer>

            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Especialista:*</FormLabel>
              <Select
                id="campo_especialista"
                style={{ width: '100%' }}
                placeholder="Digite o nome do especialista"
                showSearch
                onSearch={fetchSpecialist}
                value={formik.values.specialist}
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('specialist', value)}
                notFoundContent={loadingSpecialist ? <LoadSpinner /> : null}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {specialistArray.map((s) => (
                  <Select.Option key={s.idEspecialista} value={s.nome}>
                    {s.nome}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMsg name="specialist" />
            </Spacer>

            <Spacer style={{ width: '100%' }}>
              <FormLabel>Situação:</FormLabel>
              <Select
                id="campo_situacao"
                style={{ width: '100%' }}
                value={formik.values.status}
                disabled
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value={false}>Inativo</Select.Option>
                <Select.Option value>Ativo</Select.Option>
              </Select>
            </Spacer>
          </HorizontalContainer>

          <HorizontalContainer>
            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Assunto:*</FormLabel>
              <Select
                id="campo_assunto"
                style={{ width: '100%' }}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
                onChange={(value) => formik.setFieldValue('subject', value)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {subjects.map((s) => (
                  <Select.Option key={s} value={s}>
                    {s}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMsg name="subject" />
            </Spacer>

            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Possui certificado de conclusão?*</FormLabel>
              <Select
                id="campo_certificado"
                style={{ width: '100%' }}
                value={formik.values.hasCertificate}
                onChange={(value) =>
                  formik.setFieldValue('hasCertificate', value)
                }
                onBlur={formik.handleBlur}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value>Sim</Select.Option>
                <Select.Option value={false}>Não</Select.Option>
              </Select>
              <ErrorMsg name="hasCertificate" />
            </Spacer>

            <Spacer style={{ width: '100%' }}>
              <FormLabel>Público-alvo:</FormLabel>
              <Input
                id="campo_pubalvo"
                style={{ width: '100%' }}
                value={formik.values.audience}
                onBlur={formik.handleBlur}
                onChange={(e) =>
                  formik.setFieldValue('audience', e.target.value)
                }
                maxLength={90}
              />
              <ErrorMsg name="audience" />
            </Spacer>
          </HorizontalContainer>

          <HorizontalContainer>
            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Possui cobrança adicional?*</FormLabel>
              <Select
                id="campo_cob_adicional"
                style={{ width: '100%' }}
                value={formik.values.aditional}
                onChange={(value) => formik.setFieldValue('aditional', value)}
                onBlur={formik.handleBlur}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value>Sim</Select.Option>
                <Select.Option value={false}>Não</Select.Option>
              </Select>
              <ErrorMsg name="aditional" />
            </Spacer>

            <Spacer style={{ width: '100%', marginRight: 20 }}>
              <FormLabel>Permite exibição offline?*</FormLabel>
              <Select
                id="campo_offline"
                style={{ width: '100%' }}
                value={formik.values.allowOffline}
                onChange={(value) =>
                  formik.setFieldValue('allowOffline', value)
                }
                onBlur={formik.handleBlur}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value>Sim</Select.Option>
                <Select.Option value={false}>Não</Select.Option>
              </Select>
              <ErrorMsg name="allowOffline" />
            </Spacer>

            <Spacer style={{ width: '100%' }}>
              <FormLabel>Duração:*</FormLabel>
              <InputMask
                id="campo_duracao"
                mask="99:99:99"
                onBlur={formik.handleBlur}
                value={formik.values.duration}
                onChange={(e) =>
                  formik.setFieldValue('duration', e.target.value)
                }
              >
                <Input />
              </InputMask>

              <ErrorMsg name="duration" />
            </Spacer>
          </HorizontalContainer>

          {formik.values.aditional ? (
            <HorizontalContainer>
              <Spacer style={{ width: '100%', marginRight: 20 }}>
                <FormLabel>Valor da cobrança:*</FormLabel>
                <InputNumber
                  value={formik.values.aditionalValue}
                  onChange={(value) =>
                    formik.setFieldValue('aditionalValue', value)
                  }
                  onBlur={formik.handleBlur}
                  decimalSeparator=","
                  min={0}
                  precision={2}
                  step={0.01}
                  style={{ width: '100%' }}
                />
                <ErrorMsg name="aditionalValue" />
              </Spacer>

              <Spacer style={{ width: '100%', marginRight: 20 }}>
                <FormLabel>Valor promocional:</FormLabel>
                <InputNumber
                  value={formik.values.aditionalPromoValue}
                  onChange={(value) =>
                    formik.setFieldValue('aditionalPromoValue', value)
                  }
                  onBlur={formik.handleBlur}
                  decimalSeparator=","
                  precision={2}
                  step={0.01}
                  min={0}
                  style={{ width: '100%' }}
                />
                <ErrorMsg name="aditionalPromoValue" />
              </Spacer>

              <Spacer>
                <FormLabel>Vigência da promoção:*</FormLabel>
                <HorizontalContainer>
                  <DatePicker
                    id="campo_data_inicio"
                    locale={locale}
                    onBlur={formik.handleBlur}
                    format={dateFormat}
                    style={{ marginRight: '20px', width: 200 }}
                    value={formik.values.initialDate}
                    onChange={(e) => {
                      formik.setFieldValue('initialDate', e);
                      formik.setFieldValue('finalDate', '');
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                  até
                  <DatePicker
                    id="campo_data_fim"
                    locale={locale}
                    onBlur={formik.handleBlur}
                    format={dateFormat}
                    style={{ marginLeft: '20px', width: 200 }}
                    value={formik.values.finalDate}
                    onChange={(e) => formik.setFieldValue('finalDate', e)}
                    disabledDate={(current) =>
                      current < formik.values.initialDate
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </HorizontalContainer>
                <ErrorMsg name="initialDate" />
                <ErrorMsg name="finalDate" />
              </Spacer>
            </HorizontalContainer>
          ) : null}

          <Spacer>
            <FormLabel>Descrição:</FormLabel>
            <Input.TextArea
              id="campo_descricao"
              value={formik.values.description}
              onChange={(e) =>
                formik.setFieldValue('description', e.target.value)
              }
              onBlur={formik.handleBlur}
              style={{ height: 200 }}
              maxLength={400}
            />
            <ErrorMsg name="description" />
          </Spacer>

          <Spacer>
            <FormLabel>Objetivo:</FormLabel>
            <Input.TextArea
              id="campo_objetivo"
              style={{ height: 200 }}
              value={formik.values.objective}
              onChange={(e) =>
                formik.setFieldValue('objective', e.target.value)
              }
              onBlur={formik.handleBlur}
              maxLength={400}
            />
            <ErrorMsg name="objective" />
          </Spacer>

          {idConteudo ? (
            <Spacer>
              <FormLabel>Tags:</FormLabel>
              {loadingTagRequest ? (
                <LoadSpinner />
              ) : (
                <>
                  <DisplayTags
                    tagsArray={currentContent?.tagConteudos}
                    handleDeleteTag={handleDeleteTag}
                  />
                  <Button
                    style={{ marginTop: 5 }}
                    onClick={() => setManageTagsVisible(true)}
                  >
                    Adicionar Tags
                  </Button>
                </>
              )}
            </Spacer>
          ) : null}

          {manageTagsVisible ? (
            <AddTag
              currentTags={currentContent?.tagConteudos}
              contentId={currentContent.idConteudo}
              onCancel={() => setManageTagsVisible(false)}
              handleTags={handleTags}
            />
          ) : null}

          {(idConteudo && signedUserPerms[CONTENT_PERMS.UPDATE]) ||
          signedUserPerms[CONTENT_PERMS.CREATE] ? (
            <Spacer style={{ marginLeft: 'auto' }}>
              <Button
                id="botao_salvar"
                type="primary"
                onClick={formik.handleSubmit}
                loading={loadingRequest}
              >
                Salvar
              </Button>
            </Spacer>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export default AddContentForm;
