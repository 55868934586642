import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { getUrlQuery } from './util';

const RoleContext = createContext();

const RoleProvider = ({ children }) => {
  const [rolesArray, setRolesArray] = useState(null);

  const getRoles = useCallback(async (params = {}) => {
    const newParams = {
      Nome: params.name || undefined,
      Situacao: params.status === '' ? undefined : params.status,
    };
    try {
      const response = await axios.get(`/Perfil?${getUrlQuery(newParams)}`);

      setRolesArray(response.data.result);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error(
          'Você não possui permissao para visualizar perfis de usuários.'
        );
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao buscar perfis de usuários!');
      }
      return null;
    }
  }, []);

  const createRole = useCallback(
    async ({ name, description }) => {
      try {
        const postBody = {
          nome: name,
          descricao: description,
          dataDesativacao: '',
        };

        await axios.post('/Perfil', postBody);
        await getRoles();

        message.success('Perfil cadastrado com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissão para criar perfis de usuários.'
          );
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao buscar perfis de usuários!');
        }

        return false;
      }
    },
    [getRoles]
  );

  const updateRole = useCallback(
    async ({ id, name, description, activate }) => {
      try {
        const body = {
          nome: name,
          descricao: description,
          desativar: activate ? 0 : 1,
        };

        await axios.put(`/Perfil/${id}`, body);
        await getRoles();

        message.success('Perfil de usuário atualizado com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissao para atualizar perfis de usuários.'
          );
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao atualizar perfil de usuário!');
        }

        return false;
      }
    },
    [getRoles]
  );

  const deactivateRole = useCallback(
    async (id) => {
      try {
        await axios.delete(`/Perfil/${id}`);
        await getRoles();

        message.success('Perfil de usuário desativado com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissao para desativar perfis de usuários.'
          );
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao desativar perfil de usuário!');
        }

        return false;
      }
    },
    [getRoles]
  );

  const getRoleById = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Perfil/${id}`);

      return response.data.result;
    } catch (error) {
      message.error('Erro ao buscar perfis!');
    }
  }, []);

  const activateRole = useCallback(
    async (id) => {
      try {
        await axios.put(`/Perfil/${id}/Reativar`);
        await getRoles();

        message.success('Perfil de usuário desativado com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissao para desativar perfis de usuários.'
          );
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao desativar perfil de usuário!');
        }

        return false;
      }
    },
    [getRoles]
  );

  return (
    <RoleContext.Provider
      value={{
        rolesData: { rolesArray },
        createRole,
        getRoles,
        updateRole,
        deactivateRole,
        getRoleById,
        activateRole,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

const useRole = () => {
  const context = useContext(RoleContext);

  if (!context) {
    throw new Error('useRole must be used within an RoleProvider');
  }

  return context;
};

export { RoleProvider, useRole };
