import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import moment from 'moment';
import { getUrlQuery } from './util';

const SubscriptionContext = createContext();

const SubscriptionProvider = ({ children }) => {
  const [subsArray, setSubsArray] = useState([]);
  const [currentSub, setCurrentSub] = useState(null);
  const [specialSubs, setSpecialSubs] = useState([]);

  const getSubscriptions = useCallback(async (params = {}) => {
    const newParams = {
      NomePlano: params?.name || undefined,
      VigenciaInicial: params.initialDate
        ? moment(params.initialDate)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toJSON()
        : undefined,
      VigenciaFinal: params.finalDate
        ? moment(params.finalDate)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toJSON()
        : undefined,
      Periodicidade: params?.timeSpan || undefined,
      Situacao: params.situacao,
      Valor: params?.price || undefined,
    };

    try {
      const response = await axios.get(`/Plano?${getUrlQuery(newParams)}`);
      setSubsArray(response.data.result);
      return response.data.result;
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar planos.');
      } else {
        message.error('Erro ao visualizar planos!');
      }
    }
  }, []);

  const createSubscription = useCallback(
    async (body) => {
      try {
        const postBody = {
          nomePlano: body.name,
          vigenciaInicial: body.initialDate,
          vigenciaFinal: body?.finalDate || undefined,
          periodicidade: body.timeSpan,
          descricao: body.description,
          valor: body.price,
          situacao: true,
        };

        await axios.post(`/Plano`, postBody);
        await getSubscriptions();

        message.success('Plano cadastrado com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar planos.');
        } else if (Array.isArray(error.response.data)) {
          message.error(error.response.data[0].description);
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao criar plano!');
        }

        return false;
      }
    },
    [getSubscriptions]
  );

  const getSubById = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Plano/${id}`);

      setCurrentSub(response.data.result);
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar planos.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao visualizar plano!');
      }
    }
  }, []);

  const getSpecialBySubId = useCallback(async (id) => {
    try {
      const response = await axios.get(`/Promocao/${id}/PromoPlano`);

      setSpecialSubs(response.data.result);
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar planos.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else if (error.response.status === 404) {
        setSpecialSubs([]);
      } else {
        message.error('Erro ao visualizar plano!');
      }
    }
  }, []);

  const updateSubscription = useCallback(
    async ({ id, body }) => {
      try {
        const putBody = {
          nomePlano: body.name,
          vigenciaInicial: body.initialDate,
          vigenciaFinal: body?.finalDate || undefined,
          periodicidade: body.timeSpan,
          descricao: body.description,
          valor: body.price,
          situacao: true,
        };

        await axios.put(`/Plano/${id}`, putBody);
        await getSubscriptions();

        message.success('Plano atualizado com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar planos.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar plano!');
        }

        return false;
      }
    },
    [getSubscriptions]
  );

  const addSpecialSub = useCallback(
    async (body) => {
      try {
        const postBody = {
          nome: body.promName,
          vigenciaInicial: body.promInitialDate,
          vigencialFinal: body.promFinalDate,
          valor: body.promValue,
          idPlano: body.idPlano,
        };

        await axios.post(`/Promocao`, postBody);
        // await getSubById(body.idPlano);
        await getSpecialBySubId(body.idPlano);

        message.success('Promoção cadastrada com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para cadastrar promoções.');
        } else {
          message.error('Erro ao cadastrar promoção!');
        }

        return false;
      }
    },
    [getSpecialBySubId]
  );

  const updateSpecialSub = useCallback(
    async (body) => {
      try {
        const putBody = {
          nome: body.promName,
          vigenciaInicial: body.promInitialDate,
          vigencialFinal: body.promFinalDate,
          valor: body.promValue,
          idPlano: body.idPlano,
        };

        await axios.put(`/Promocao/${body.idPromo}`, putBody);
        // await getSubById(body.idPlano);
        await getSpecialBySubId(body.idPlano);

        message.success('Promoção alterada com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar promoções.');
        } else {
          message.error('Erro ao alterar promoção!');
        }

        return false;
      }
    },
    [getSpecialBySubId]
  );

  const deactivateSpecial = useCallback(
    async (info) => {
      try {
        await axios.delete(`/Promocao/${info.idPromo}`);
        // await getSubById(info.idPlano);

        await getSpecialBySubId(info.idPlano);
        message.success('Promoção excluída com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para desativar promoções.');
        } else {
          message.error('Erro ao desativar promoção!');
        }

        return false;
      }
    },
    [getSpecialBySubId]
  );

  const deleteSubscription = useCallback(
    async (id) => {
      try {
        await axios.delete(`/Plano/${id}`);
        await getSubscriptions();

        message.success('Plano desativado com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para desativar planos.');
        } else {
          message.error('Erro ao desativar plano!');
        }
        return false;
      }
    },
    [getSubscriptions]
  );

  const activateSubscription = useCallback(
    async (id) => {
      try {
        await axios.put(`/Plano/${id}/Reativar`);
        await getSubscriptions();

        message.success('Plano ativado com sucesso.');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para ativar planos.');
        } else {
          message.error('Erro ao ativar plano!');
        }
        return false;
      }
    },
    [getSubscriptions]
  );

  return (
    <SubscriptionContext.Provider
      value={{
        subsData: { subsArray, currentSub, specialSubs },
        createSubscription,
        getSubscriptions,
        getSubById,
        getSpecialBySubId,
        updateSubscription,
        addSpecialSub,
        updateSpecialSub,
        deactivateSpecial,
        deleteSubscription,
        activateSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscription = () => {
  const context = useContext(SubscriptionContext);

  if (!context) {
    throw new Error(
      'useSubscription must be used within an SubscriptionProvider'
    );
  }

  return context;
};

export { SubscriptionProvider, useSubscription };
