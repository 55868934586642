import React, { useState } from 'react';
import { Input, Button, Modal } from 'antd';
import { Formik, Form } from 'formik';
import { ErrorMsg, FormLabel, Spacer } from '../../../components/Form/index';
import { addRoleSchema } from './validationSchema';
import { useRole } from '../../../hooks/Role';

const AddRole = ({ visible, onCancel, selectedRole }) => {
  const [loading, setLoading] = useState(false);

  const isUpdate = Boolean(selectedRole);

  const { updateRole, createRole } = useRole();
  const handleRequest = async (formikValues) => {
    let result = null;

    setLoading(true);
    if (isUpdate) {
      result = await updateRole({
        id: selectedRole.codigo,
        ...formikValues,
        activate: !selectedRole.dataDesativacao,
      });
    } else {
      result = await createRole(formikValues);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };

  return (
    <Modal
      visible={visible}
      title={
        isUpdate ? 'Alterar Perfil de Usuário' : 'Incluir Perfil de Usuário'
      }
      onCancel={onCancel}
      footer={null}
    >
      <Formik>
        <Formik
          validationSchema={addRoleSchema}
          initialValues={
            isUpdate
              ? { name: selectedRole.nome, description: selectedRole.descricao }
              : { name: '', description: '' }
          }
          onSubmit={(values) => handleRequest(values)}
        >
          {(formik) => (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <Spacer>
                <FormLabel>Nome:*</FormLabel>
                <Input
                  id="campo_nome"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  onBlur={formik.handleBlur}
                  maxLength={90}
                />
                <ErrorMsg name="name" />
              </Spacer>

              <Spacer>
                <FormLabel>Descrição:*</FormLabel>
                <Input
                  id="campo_descricao"
                  value={formik.values.description}
                  onChange={(e) =>
                    formik.setFieldValue('description', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  maxLength={90}
                />
                <ErrorMsg name="description" />
              </Spacer>

              <Spacer style={{ marginLeft: 'auto' }}>
                <Button
                  id="botao_salvar"
                  type="primary"
                  loading={loading}
                  onClick={formik.handleSubmit}
                >
                  Salvar
                </Button>
              </Spacer>
            </Form>
          )}
        </Formik>
      </Formik>
    </Modal>
  );
};

export default AddRole;
