const loadAudio = (file) =>
  new Promise((resolve, reject) => {
    try {
      const audio = document.createElement('audio');
      audio.preload = 'metadata';

      audio.onloadedmetadata = function () {
        resolve(this);
      };

      audio.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };

      audio.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export default loadAudio;
