import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Input, DatePicker } from 'antd';
import { useSubscription } from '../../../hooks/Subscription';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import GoBackButton from '../../../components/shared/GoBackButton';
import { Spacer, FormLabel } from '../../../components/Form/index';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import AddSpecial from './SpecialSub/AddSpecial';
import SpecialSubTable from './SpecialSub/SpecialSubTable';
import ToggleSubscription from './ToggleSubscription';

const dateFormat = 'DD/MM/YYYY';

const SubDetail = ({ match }) => {
  const { getSubById, subsData, getSpecialBySubId } = useSubscription();
  const { currentSub, specialSubs } = subsData;
  const [loading, setLoading] = useState(false);
  const [loadingSpecials, setLoadingSpecials] = useState(false);
  const { idPlano } = match.params;

  const [addSpecialVisible, setAddSpecialVisible] = useState(false);
  const [deleteSubVisible, setDeleteSubVisible] = useState(false);

  useEffect(() => {
    const fetchSub = async () => {
      setLoading(true);
      await getSubById(idPlano);
      setLoading(false);
    };
    fetchSub();
  }, [getSubById, idPlano]);

  useEffect(() => {
    const fecthSpecials = async () => {
      setLoadingSpecials(true);
      await getSpecialBySubId(idPlano);
      setLoadingSpecials(false);
    };
    fecthSpecials();
  }, [getSpecialBySubId, idPlano]);

  if (loading || currentSub === null) {
    return <LoadSpinner />;
  }

  return (
    <>
      <GoBackButton to="/dashboard/planos" />

      <Card title={currentSub.nome}>
        <InfoContainer>
          <Spacer>
            <FormLabel>Vigência do plano:</FormLabel>
            <HorizontalContainer>
              <DatePicker
                id="campo_data_inicio"
                locale={locale}
                format={dateFormat}
                style={{ marginRight: '20px', width: 200 }}
                disabled
                value={moment(currentSub?.vigenciaInicial)}
              />
              {currentSub?.vigenciaFinal ? (
                <>
                  até
                  <DatePicker
                    id="campo_data_fim"
                    locale={locale}
                    format={dateFormat}
                    style={{ marginLeft: '20px', width: 200 }}
                    disabled
                    value={moment(currentSub?.vigenciaFinal)}
                  />
                </>
              ) : null}
            </HorizontalContainer>
          </Spacer>

          <HorizontalContainer>
            <Spacer>
              <FormLabel>Valor (R$):*</FormLabel>
              <Input
                id="campo_valor_plano"
                style={{ width: 200, marginRight: 30 }}
                disabled
                value={currentSub?.valor.toFixed(2)}
              />
            </Spacer>

            <Spacer>
              <FormLabel>Periodicidade:*</FormLabel>
              <Input
                id="campo_nome_plano"
                disabled
                value={currentSub?.periodicidade}
              />
            </Spacer>
          </HorizontalContainer>

          <Spacer>
            <FormLabel>Descrição:*</FormLabel>
            <Input.TextArea
              id="campo_descricao_plano"
              disabled
              value={currentSub?.descricao}
              style={{ height: 200 }}
            />
          </Spacer>
        </InfoContainer>
      </Card>

      <Spacer>
        <SpecialSubTable
          loadingData={loadingSpecials}
          data={specialSubs}
          handleAddSpecial={setAddSpecialVisible}
        />
      </Spacer>

      {addSpecialVisible ? (
        <AddSpecial
          visible
          onCancel={() => setAddSpecialVisible(false)}
          idPlano={currentSub?.id}
          specialSubs={specialSubs}
        />
      ) : null}

      {deleteSubVisible ? (
        <ToggleSubscription
          visible
          selected={currentSub?.id}
          onCancel={() => setDeleteSubVisible(false)}
        />
      ) : null}
    </>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SubDetail;
