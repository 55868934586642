import React, { useState } from 'react';
import { Input, Button, Upload, Select } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, ErrorMsg, Spacer } from '../../../components/Form/index';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import * as yup from 'yup';
import { useSpecialist } from '../../../hooks/Specialist';
import { useHistory } from 'react-router-dom';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import getBase64 from '../../../components/shared/getBase64';
import { SPECIALIST_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';
import InputMask from 'react-input-mask';

const formSchema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório.'),
  email: yup
    .string()
    .email('E-mail inválido.')
    .required('Este campo é obrigatório.'),
  phoneNumber: yup.string().required('Este campo é obrigatório.'), // melhorar
  facebook: yup.string(),
  instagram: yup.string(),
  linkedin: yup.string(),
  youtube: yup.string(),
  titok: yup.string(),
  photo: yup.string().required('Este campo é obrigatório.'),
  description: yup.string().required('Este campo é obrigatório.'),
});

const AddSpecialist = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const history = useHistory();
  const {
    createSpecialist,
    updateSpecialist,
    specialistsData,
  } = useSpecialist();
  const { currentSpecialist } = specialistsData;
  const { idEspecialista } = match.params;

  const uploadButton = (
    <div>
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Enviar imagem</div>
    </div>
  );

  const handleImage = async (info, formik) => {
    setLoadingImage(true);
    const result = await getBase64(info.file.originFileObj);
    formik.setFieldValue('photo', result);
    setLoadingImage(false);
  };

  const handleRequest = async (formikValues, formikBag) => {
    let result = null;

    if (formikValues.phoneNumber.includes('_')) {
      formikBag.setFieldError('phoneNumber', 'Telefone inválido.');
      return;
    }

    setLoading(true);
    if (idEspecialista) {
      result = await updateSpecialist({ ...formikValues, idEspecialista });
    } else {
      result = await createSpecialist(formikValues);
    }
    setLoading(false);

    if (result) {
      history.push('/dashboard/especialistas');
    }
  };

  if (idEspecialista && currentSpecialist === null) {
    return <LoadSpinner />;
  }

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={
        idEspecialista
          ? {
              name: currentSpecialist?.nome,
              email: currentSpecialist?.eMail,
              phoneNumber: currentSpecialist?.telefone,
              facebook: currentSpecialist?.facebook,
              instagram: currentSpecialist?.instagram,
              linkedin: currentSpecialist?.linkedin,
              youtube: currentSpecialist?.youtube,
              tiktok: currentSpecialist?.tikTok,
              description: currentSpecialist?.resumoExperiencia,
              photo: currentSpecialist?.fotoPerfil,
              phoneType: currentSpecialist?.telefone.length === 15 ? 0 : 1,
            }
          : {
              name: '',
              email: '',
              phoneNumber: '',
              facebook: '',
              instagram: '',
              linkedin: '',
              youtube: '',
              tiktok: '',
              description: '',
              photo: '',
              phoneType: 0,
            }
      }
      onSubmit={(values, formikBag) => handleRequest(values, formikBag)}
    >
      {(formik) => (
        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <Spacer style={{ margin: '5px auto 10px' }}>
            <Upload
              listType="picture-card"
              showUploadList={false}
              onChange={(e) => handleImage(e, formik)}
              className="upload-input"
              accept="image/*"
            >
              {formik.values.photo ? (
                <img
                  src={formik.values.photo}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <ErrorMsg name="photo" />
          </Spacer>

          <Spacer style={{ marginTop: 0 }}>
            <FormLabel>Nome:*</FormLabel>
            <Input
              id="campo_nome"
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              value={formik.values.name}
              maxLength={90}
            />
            <ErrorMsg name="name" />
          </Spacer>

          <Spacer>
            <FormLabel>Email:*</FormLabel>
            <Input
              id="campo_email"
              type="email"
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              value={formik.values.email}
            />
            <ErrorMsg name="email" />
          </Spacer>

          <HorizontalContainer>
            <Spacer style={{ width: '100%', marginRight: '10px' }}>
              <FormLabel>Tipo de telefone:</FormLabel>
              <Select
                style={{ width: '100%' }}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue('phoneNumber', '');
                  formik.setFieldValue('phoneType', value);
                }}
                value={formik.values.phoneType}
              >
                <Select.Option value={0}>Celular</Select.Option>
                <Select.Option value={1}>Telefone fixo</Select.Option>
              </Select>
            </Spacer>

            {formik.values.phoneType === 0 ? (
              <Spacer style={{ width: '100%' }}>
                <FormLabel>Celular:*</FormLabel>
                <InputMask
                  id="campo_telefone"
                  mask="(99) 99999-9999"
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  onChange={(e) =>
                    formik.setFieldValue('phoneNumber', e.target.value)
                  }
                >
                  <Input />
                </InputMask>

                <ErrorMsg name="phoneNumber" />
              </Spacer>
            ) : (
              <Spacer style={{ width: '100%' }}>
                <FormLabel>Telefone:*</FormLabel>
                <InputMask
                  id="campo_telefone"
                  mask="(99) 9999-9999"
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  onChange={(e) =>
                    formik.setFieldValue('phoneNumber', e.target.value)
                  }
                >
                  <Input />
                </InputMask>
                <ErrorMsg name="phoneNumber" />
              </Spacer>
            )}
          </HorizontalContainer>

          <SocialMedia>
            <Spacer style={{ width: '100%', marginRight: '20px' }}>
              <FormLabel>Facebook:</FormLabel>
              <Input
                id="campo_facebook"
                value={formik.values.facebook}
                onChange={(e) =>
                  formik.setFieldValue('facebook', e.target.value)
                }
                onBlur={formik.handleBlur}
                maxLength={90}
              />
            </Spacer>

            <Spacer style={{ width: '100%' }}>
              <FormLabel>Instagram:</FormLabel>
              <Input
                id="campo_instagram"
                value={formik.values.instagram}
                onChange={(e) =>
                  formik.setFieldValue('instagram', e.target.value)
                }
                onBlur={formik.handleBlur}
                maxLength={90}
              />
            </Spacer>
          </SocialMedia>

          <SocialMedia>
            <Spacer style={{ width: '100%', marginRight: '20px' }}>
              <FormLabel>LinkedIn:</FormLabel>
              <Input
                id="campo_linkedin"
                value={formik.values.linkedin}
                onChange={(e) =>
                  formik.setFieldValue('linkedin', e.target.value)
                }
                onBlur={formik.handleBlur}
                maxLength={90}
              />
            </Spacer>

            <Spacer style={{ width: '100%' }}>
              <FormLabel>YouTube:</FormLabel>
              <Input
                id="campo_youtube"
                value={formik.values.youtube}
                onChange={(e) =>
                  formik.setFieldValue('youtube', e.target.value)
                }
                onBlur={formik.handleBlur}
                maxLength={90}
              />
            </Spacer>
          </SocialMedia>

          <Spacer>
            <FormLabel>Tiktok:</FormLabel>
            <Input
              id="campo_tiktok"
              onBlur={formik.handleBlur}
              onChange={(e) => formik.setFieldValue('tiktok', e.target.value)}
              value={formik.values.tiktok}
              maxLength={90}
            />
            <ErrorMsg name="tiktok" />
          </Spacer>

          <Spacer>
            <FormLabel>Resumo da experiência:*</FormLabel>
            <Input.TextArea
              id="campo_resumo"
              onChange={(e) =>
                formik.setFieldValue('description', e.target.value)
              }
              value={formik.values.description}
              onBlur={formik.handleBlur}
              style={{ height: 200 }}
              maxLength={400}
            />
            <ErrorMsg name="description" />
          </Spacer>

          {(idEspecialista && signedUserPerms[SPECIALIST_PERMS.UPDATE]) ||
          signedUserPerms[SPECIALIST_PERMS.CREATE] ? (
            <Spacer style={{ marginLeft: 'auto' }}>
              <Button
                type="primary"
                id="botao_salvar"
                onClick={formik.handleSubmit}
                loading={loading}
              >
                Salvar
              </Button>
            </Spacer>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

const SocialMedia = styled.div`
  display: flex;
  justifu-content: space-between;
`;

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

export default AddSpecialist;
