import React, { useEffect, useState, useCallback } from 'react';
import { Button, Transfer } from 'antd';
import { useSubscription } from '../../../../../hooks/Subscription';
import { useContent } from '../../../../../hooks/Content';
import LoadSpinner from '../../../../../components/shared/LoadSpinner';
import { usePerm } from '../../../../../hooks/Permission';
import { CONTENT_PERMS } from '../../../../../constants/Permissions';

const SubscriptionList = ({ contentId }) => {
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [subs, setSubs] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState([]);
  const { getSubscriptions } = useSubscription();
  const { updateContentSubscription, getContentSub } = useContent();
  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const handleInitialPerms = useCallback((allPerms, contentSubs) => {
    const selecKeys = contentSubs.map((p) => p.idPlano);

    const allSubsTranformed = allPerms.map((s) => ({
      key: s.id,
      name: s.nome,
    }));

    setSubs(allSubsTranformed);
    setSelectedSubs(selecKeys);
  }, []);

  useEffect(() => {
    const fetchSubs = async () => {
      setLoadingFetch(true);
      const result = await getSubscriptions({ status: true });
      const contSubs = await getContentSub(contentId);

      handleInitialPerms(result, contSubs);
      setLoadingFetch(false);
    };
    fetchSubs();
  }, [getSubscriptions, handleInitialPerms, getContentSub, contentId]);

  const handleTransfer = (source, dir, target) => {
    setSelectedSubs(source);
  };

  const handleRequest = async () => {
    const uniqueKeys = [...new Set(selectedSubs)];
    const bodyArray = uniqueKeys.map((subId) => ({
      idPlano: subId,
      idConteudo: contentId,
    }));

    setLoadingRequest(true);
    await updateContentSubscription({ bodyArray, contentId });
    setLoadingRequest(false);
  };

  if (loadingFetch) {
    return <LoadSpinner />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Transfer
        showSearch
        dataSource={subs}
        targetKeys={selectedSubs}
        render={(item) => item.name}
        onChange={handleTransfer}
        listStyle={{ width: '100%', height: '100%' }}
        titles={['Planos disponíveis', 'Planos selecionados']}
        locale={{
          itemsUnit: 'itens',
          notFoundContent: 'Lista vazia',
          searchPlaceholder: 'Pesquise aqui',
          selectAll: 'Selecionar todos os itens',
          selectInvert: 'Inverter seleção',
        }}
      />

      {signedUserPerms[CONTENT_PERMS.UPDATE] ? (
        <div style={{ margin: '10px 0 10px auto' }}>
          <Button
            type="primary"
            onClick={handleRequest}
            loading={loadingRequest}
          >
            Salvar
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SubscriptionList;
