import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Tooltip, Space, Switch } from 'antd';
import { EditOutlined, FilterOutlined } from '@ant-design/icons';
import getColumnSearchProps from '../../../components/shared/getColumnSearchProps';
import { useSpecialist } from '../../../hooks/Specialist';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { SearchSpecialist, ToggleSpecialist } from './index';
import { SPECIALIST_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';

const Specialist = ({ history }) => {
  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'eMail',
      sorter: (a, b) => a.eMail.localeCompare(b.eMail),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('eMail'),
    },
    {
      title: 'Telefone',
      key: 'phoneNumber',
      dataIndex: 'telefone',
      sorter: (a, b) => a.telefone.localeCompare(b.telefone),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Situação',
      key: 'situacao',
      dataIndex: 'situacao',
      render: (text, record) => (record.situacao ? 'Ativo' : 'Desativado'),
    },
    {
      title: 'Ações',
      key: 'phoneNumber',
      dataIndex: 'telefone',
      render: (text, record) => (
        <Space>
          {signedUserPerms[SPECIALIST_PERMS.DELETE] ? (
            <Tooltip title={!record.situacao ? 'Ativar' : 'Desativar'}>
              <Switch
                checked={record.situacao}
                onChange={() => {
                  setSelectedSpec(record);
                  setToggleSpecVisible(true);
                }}
              />
            </Tooltip>
          ) : null}

          {signedUserPerms[SPECIALIST_PERMS.UPDATE] ? (
            <Tooltip title="Alterar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() =>
                  history.push(
                    `/dashboard/alterar-especialista/${record.idEspecialista}/especialista`
                  )
                }
              />
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const [toggleSpecVisible, setToggleSpecVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const { getAllSpecialists, specialistsData } = useSpecialist();
  const { specialistArray } = specialistsData;

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      await getAllSpecialists();
      setLoading(false);
    };
    fetchAll();
  }, [getAllSpecialists]);

  return (
    <Card
      title="Especialistas"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Space>
          {searchVisible ? (
            <Button
              danger
              id="botao_desativar_filtro"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(false)}
            >
              Desativar filtro
            </Button>
          ) : (
            <Button
              id="botao_filtrar"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(true)}
            >
              Filtrar Resultado
            </Button>
          )}

          {signedUserPerms[SPECIALIST_PERMS.CREATE] ? (
            <Button
              id="botao_novo_especialista"
              type="primary"
              onClick={() => {
                history.push('/dashboard/criar-especialista');
              }}
            >
              Novo Especialista
            </Button>
          ) : null}
        </Space>
      }
    >
      {searchVisible ? <SearchSpecialist loadingFunction={setLoading} /> : null}

      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={specialistArray}
            rowKey={(record) => record.idEspecialista}
            locale={{
              emptyText: 'Nenhum dado encontrado.',
              triggerAsc: null,
              triggerDesc: null,
            }}
            scroll={{ x: true }}
          />
        </>
      )}

      {toggleSpecVisible ? (
        <ToggleSpecialist
          selected={selectedSpec}
          onCancel={() => setToggleSpecVisible(false)}
        />
      ) : null}
    </Card>
  );
};

export default Specialist;
