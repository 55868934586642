import React from 'react';
import { Button } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const GoBackButton = ({ to }) => {
  const history = useHistory();
  return (
    <Button
      type="ghost"
      style={{ marginBottom: '10px' }}
      icon={<RollbackOutlined />}
      onClick={() => history.push(to)}
    >
      Voltar
    </Button>
  );
};

export default GoBackButton;
