import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import logoVertical from '../../assets/images/logo_vertical.png';

const Welcome = () => {
  return (
    <Card>
      <CardBody>
        <div style={{ margin: '10px auto' }}>
          <LogoImage alt="logo do mundo em cores" src={logoVertical} />
        </div>

        <div style={{ margin: '0 auto' }}>
          Bem-vindo ao sistema do Mundo em Cores, navegue pelo sistema
          utilizando o menu lateral.
        </div>
      </CardBody>
    </Card>
  );
};

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoImage = styled.img``;

export default Welcome;
