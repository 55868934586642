import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Select } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer } from '../../../components/Form/index';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { useRole } from '../../../hooks/Role';
import { useUser } from '../../../hooks/User';
import debounce from 'lodash.debounce';
import InputMask from 'react-input-mask';

const SearchUsers = () => {
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { getRoles, rolesData } = useRole();
  const { getUsers } = useUser();
  const { rolesArray } = rolesData;

  const handleRoleSearch = debounce(async (info) => {
    setLoadingRoles(true);
    await getRoles({ name: info, status: true });
    setLoadingRoles(false);
  }, 300);

  const getRoleId = (role) =>
    rolesArray.filter((r) => r.nome === role)[0].codigo;

  const handleRequest = async (formValues) => {
    const formCopy = JSON.parse(JSON.stringify(formValues));

    if (formValues.role) {
      formCopy.role = getRoleId(formValues.role);
    }

    if (formValues.phoneNumber) {
      formCopy.phoneNumber = formValues.phoneNumber
        .split('')
        .filter((c) => c !== '_')
        .join('');
    }
    setLoadingRequest(true);
    await getUsers(formCopy);
    setLoadingRequest(false);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setLoadingRoles(true);
      await getRoles({ name: '', status: true });
      setLoadingRoles(false);
    };
    fetchRoles();
  }, [getRoles]);

  return (
    <FormContainer>
      <FormTitle>Filtrar usuários</FormTitle>
      <Formik
        initialValues={{ name: '', email: '', phoneNumber: '', role: '' }}
        onSubmit={handleRequest}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Nome:</FormLabel>
                <Input
                  id="campo_nome"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>E-mail:</FormLabel>
                <Input
                  id="campo_email"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue('email', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                />
              </FieldContainer>
            </HorizontalContainer>

            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Telefone:</FormLabel>
                <InputMask
                  id="campo_telefone"
                  mask="(99) 99999-9999"
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  onChange={(e) =>
                    formik.setFieldValue('phoneNumber', e.target.value)
                  }
                >
                  <Input />
                </InputMask>
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Perfil</FormLabel>
                <Select
                  id="campo_perfil"
                  showSearch
                  allowClear
                  onSearch={handleRoleSearch}
                  style={{ width: '100%' }}
                  placeholder="Selecione um perfil"
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('role', value)}
                  value={formik.values.role}
                  notFoundContent={loadingRoles ? <LoadSpinner /> : null}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {rolesArray?.map((r) => (
                    <Select.Option value={r.nome} key={`${r.codigo}-${r.nome}`}>
                      {r.nome}
                    </Select.Option>
                  ))}
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <Spacer style={{ margin: '15px 0 0 auto' }}>
              <Button
                type="primary"
                onClick={() => {
                  formik.handleSubmit();
                }}
                loading={loadingRequest}
              >
                Pesquisar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;

const FormTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 800;
`;

export default SearchUsers;
