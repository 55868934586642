import React from 'react';
import { Card } from 'antd';
import GoBackButton from '../../../../components/shared/GoBackButton';
import AddContentForm from '../AddContentForm';

const AddContent = (props) => {
  return (
    <>
      <GoBackButton to="/dashboard/tabela-conteudos" />
      <Card title="Adicionar Conteúdo">
        <AddContentForm {...props} />
      </Card>
    </>
  );
};

export default AddContent;
