import React, { useEffect, useState } from 'react';
import { Transfer } from 'antd';

const filter = (inputValue, option) =>
  option.name.toLowerCase().indexOf(inputValue) > -1;

const HighightTransfer = ({ formik, contentArray }) => {
  const [contents, setContents] = useState([]);

  const handleTransfer = (event) => {
    formik.setFieldValue('highlight', event.source);
  };

  useEffect(() => {
    const allConts = contentArray.map((c) => ({
      key: c.idConteudo,
      name: c.titulo,
    }));

    setContents(allConts);
  }, [contentArray]);

  return (
    <Transfer
      showSearch
      dataSource={contents}
      targetKeys={formik.values.highlight}
      render={(item) => item.name}
      filterOption={filter}
      onChange={(source, dir, target) =>
        handleTransfer({ source, dir, target }, formik)
      }
      listStyle={{ width: '100%', height: '100%' }}
      titles={['Conteúdos', 'Conteúdos Destaque']}
      locale={{
        itemsUnit: 'itens',
        notFoundContent: 'Lista vazia',
        searchPlaceholder: 'Pesquise aqui',
      }}
    />
  );
};

export default HighightTransfer;
