import styled from 'styled-components';
import Colors from '../../constants/Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const FormContainer = styled.div`
  flex: 1;
  padding 3%;
  display: flex;
  flex-direction: column;
`;

export const ColorsImage = styled.img`
  flex: 1;
`;

export const LogoImage = styled.img``;

export const LoginMainText = styled.p`
  margin-top: 5%;
  font-weight: 600;
  font-size: 24px;
  color: ${Colors.primary};
`;

export const LoginSecondaryText = styled.p`
  margin-top: 5%;
  font-weight: 600;
`;

export const Background = styled.div`
  height: 50%;
  width: 100%;
  background-color: ${Colors.primary};
`;

export const CardContainer = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
`;

export const SuccessMessage = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;
