import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { FormLabel, Spacer, ErrorMsg } from '../../../components/Form/index';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../../../hooks/Auth';

const formValidation = yup.object().shape({
  newPassword: yup.string().required('A nova senha é obrigatória!'),
  confirmNewPassword: yup.string().required('Confirmar a senha é obrigatório!'),
});

const ChangePassword = ({ visible, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const { changeUserPassword } = useAuth();

  const handleRequest = async (formikValues, formikBag) => {
    if (formikValues.newPassword !== formikValues.confirmNewPassword) {
      formikBag.setFieldError(
        'confirmNewPassword',
        'As senhas não são iguais!'
      );
      return;
    }

    setLoading(true);
    const result = await changeUserPassword(formikValues.newPassword);
    setLoading(false);

    if (result.success) {
      onCancel();
    }
  };
  return (
    <Modal
      title="Alterar senha"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Formik
        initialValues={{
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={formValidation}
        onSubmit={(values, formikBag) => handleRequest(values, formikBag)}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <Spacer>
              <FormLabel>Nova senha:</FormLabel>
              <Input.Password
                id="campo_nova_senha"
                style={{ width: '100%' }}
                value={formik.values.newPassword}
                onChange={(e) =>
                  formik.setFieldValue('newPassword', e.target.value)
                }
                onBlur={formik.handleBlur}
                iconRender={(isVisible) =>
                  isVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <ErrorMsg name="newPassword" />
            </Spacer>

            <Spacer>
              <FormLabel>Confirmar a nova senha:</FormLabel>
              <Input.Password
                id="campo_confirmar_nova_senha"
                style={{ width: '100%' }}
                value={formik.values.confirmNewPassword}
                onChange={(e) =>
                  formik.setFieldValue('confirmNewPassword', e.target.value)
                }
                onBlur={formik.handleBlur}
                iconRender={(isVisible) =>
                  isVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
              <ErrorMsg name="confirmNewPassword" />
            </Spacer>

            <Spacer style={{ marginLeft: 'auto' }}>
              <Button
                id="botao_salvar"
                type="primary"
                onClick={formik.handleSubmit}
                loading={loading}
              >
                Salvar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
