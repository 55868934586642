import React, { useState } from 'react';
import {
  PlusOutlined,
  EditOutlined,
  VideoCameraOutlined,
  CustomerServiceOutlined,
  FileOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Card, Collapse, Button, Space } from 'antd';
import AddSection from './Section/AddSection';
import DeactivateSection from './Section/DeactivateSection';
import { AddLesson, DeactivateLesson } from './Lesson/index';
import {
  AddTag,
  DisplayTags,
} from '../../../../components/Dashboard/Tags/index';
import { useContent } from '../../../../hooks/Content';
import { usePerm } from '../../../../hooks/Permission';
import { MODULE_PERMS } from '../../../../constants/Permissions';

const { Panel } = Collapse;

const PanelHeader = ({ title }) => (
  <span style={{ marginRight: 'auto' }}>{title}</span>
);

const AddMedia = ({ contentId }) => {
  const [currentSec, setCurrentSec] = useState(null);
  const [manageTagsVisible, setManageTagsVisible] = useState(false);
  const [createSecVisible, setCreateSecVisible] = useState(false);
  const [createLessonVisible, setCreateLessonVisible] = useState(false);
  const [deleteLessonVisible, setDeleteLessonVisible] = useState(false);
  const [deleteSectionVisible, setDeleteSectionVisible] = useState(false);
  const [selectedSec, setSelectedSec] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const { updateLessonTags, contentData } = useContent();
  const { currentSections } = contentData;
  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const handleTags = async (tagsArray, lessonId) => {
    const bodyArray = tagsArray.map((t) => ({
      idTag: t.idTag,
      idAula: lessonId,
    }));

    return updateLessonTags({ bodyArray, contentId });
  };

  const handleDeleteTag = async (newTags) => {
    await updateLessonTags({ bodyArray: newTags, contentId });
  };

  return (
    <Card>
      {signedUserPerms[MODULE_PERMS.CREATE] ? (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setSelectedSec(null);
            setCreateSecVisible(true);
          }}
          style={{ marginBottom: '20px' }}
        >
          Adicionar módulo
        </Button>
      ) : null}

      <Collapse expandIconPosition="left" style={{ width: '100%' }}>
        {currentSections?.map((p) => (
          <Panel
            header={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <PanelHeader
                title={p.titulo}
                onEdit={() => {
                  setSelectedSec(p);
                  setCreateSecVisible(true);
                }}
              />
            }
            style={{ width: '100%' }}
            key={p.idModulo}
            extra={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Space>
                {signedUserPerms[MODULE_PERMS.UPDATE] ? (
                  <Button
                    type="ghost"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedSec(p);
                      setCreateSecVisible(true);
                    }}
                  />
                ) : null}

                {signedUserPerms[MODULE_PERMS.DELETE] ? (
                  <Button
                    type="ghost"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedSec(p);
                      setDeleteSectionVisible(true);
                    }}
                  />
                ) : null}
              </Space>
            }
          >
            {signedUserPerms[MODULE_PERMS.UPDATE] ? (
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentSec(p);
                  setSelectedLesson(null);
                  setCreateLessonVisible(true);
                }}
                style={{ marginBottom: '10px' }}
              >
                Adicionar aula
              </Button>
            ) : null}

            <Collapse>
              {p.lessons?.map((l) => (
                <Panel
                  header={<PanelHeader title={l.titulo} />}
                  key={l.id}
                  extra={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Space>
                      {signedUserPerms[MODULE_PERMS.UPDATE] ? (
                        <Button
                          type="ghost"
                          icon={<EditOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentSec(p);
                            setSelectedLesson(l);
                            setCreateLessonVisible(true);
                          }}
                        />
                      ) : null}
                      {signedUserPerms[MODULE_PERMS.UPDATE] ? (
                        <Button
                          type="ghost"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={(event) => {
                            event.stopPropagation();
                            setCurrentSec(p);
                            setSelectedLesson(l);
                            setDeleteLessonVisible(true);
                          }}
                        />
                      ) : null}
                    </Space>
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <VideoCameraOutlined />
                    <span style={{ marginLeft: '10px' }}>{l.link}</span>
                  </div>

                  {l.urlAudio ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <CustomerServiceOutlined />
                      <span style={{ marginLeft: '10px' }}>{l.nomeAudio}</span>
                    </div>
                  ) : null}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <FileOutlined />
                    <span style={{ marginLeft: '10px' }}>{l.nomeArquivo}</span>
                  </div>

                  <div>
                    <div>Tags</div>
                    <DisplayTags
                      handleDeleteTag={handleDeleteTag}
                      tagsArray={l.tagAulas}
                    />
                    {signedUserPerms[MODULE_PERMS.UPDATE] ? (
                      <Button
                        style={{ marginTop: 5 }}
                        onClick={() => {
                          setSelectedLesson(l);
                          setManageTagsVisible(true);
                        }}
                      >
                        Adicionar Tags
                      </Button>
                    ) : null}
                  </div>
                </Panel>
              ))}
            </Collapse>
          </Panel>
        ))}
      </Collapse>

      {createSecVisible ? (
        <AddSection
          selected={selectedSec}
          onCancel={() => setCreateSecVisible(false)}
          contentId={contentId}
        />
      ) : null}

      {createLessonVisible ? (
        <AddLesson
          selected={selectedLesson}
          section={currentSec}
          onCancel={() => setCreateLessonVisible(false)}
        />
      ) : null}

      {deleteLessonVisible ? (
        <DeactivateLesson
          selected={selectedLesson}
          section={currentSec}
          onCancel={() => setDeleteLessonVisible(false)}
        />
      ) : null}

      {deleteSectionVisible ? (
        <DeactivateSection
          section={selectedSec}
          onCancel={() => setDeleteSectionVisible(false)}
        />
      ) : null}

      {manageTagsVisible ? (
        <AddTag
          currentTags={selectedLesson?.tagAulas}
          contentId={selectedLesson?.id}
          handleTags={handleTags}
          onCancel={() => setManageTagsVisible(false)}
        />
      ) : null}
    </Card>
  );
};

export default AddMedia;
