import React, { useState } from 'react';
import {
  Container,
  LogoImage,
  FormContainer,
  LoginMainText,
  LoginSecondaryText,
  ColorsImage,
  SuccessMessage,
} from './styles';
import { Button, Input } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, ErrorMsg, Spacer } from '../../components/Form/index';
import logoHorizontal from '../../assets/images/logo_horizontal.png';
import loginBackground from '../../assets/images/login_image.png';
import { forgotPasswordSchema } from './formValidation';
import { useAuth } from '../../hooks/Auth';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { passwordForgot } = useAuth();
  const history = useHistory();

  const handleRequest = async (formikValues) => {
    setLoading(true);
    const result = await passwordForgot(formikValues.email);

    setLoading(false);

    if (result) {
      setDone(true);
    }
  };

  return (
    <Container>
      <FormContainer>
        <div>
          <LogoImage alt="Logotipo do Mundo em Cores" src={logoHorizontal} />
        </div>
        <LoginMainText>Bem-vindo ao seu gerenciador de conteúdos</LoginMainText>
        {!done ? (
          <>
            <LoginSecondaryText>
              Informe os dados abaixo para recuperar a senha
            </LoginSecondaryText>

            <Formik
              initialValues={{ email: '' }}
              validationSchema={forgotPasswordSchema}
              onSubmit={(values) => handleRequest(values)}
            >
              {(formik) => (
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                  <Spacer>
                    <FormLabel>E-mail:</FormLabel>
                    <Input
                      id="campo_email"
                      size="large"
                      type="email"
                      style={{ width: '100%', marginTop: '2px' }}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue('email', e.target.value)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMsg name="email" />
                  </Spacer>

                  <Spacer style={{ margin: '0 auto' }}>
                    <Button
                      type="primary"
                      id="botao_recuperar_senha"
                      onClick={formik.handleSubmit}
                      loading={loading}
                    >
                      Recuperar senha
                    </Button>
                  </Spacer>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <SuccessMessage>
            Instruções de acesso à conta foram enviadas ao e-mail informado.
          </SuccessMessage>
        )}

        <Spacer style={{ margin: '10px auto 0' }}>
          <Button
            type="link"
            id="botao_recuperar_senha"
            onClick={() => history.push('/')}
          >
            Voltar ao Login
          </Button>
        </Spacer>
      </FormContainer>
      <ColorsImage alt="cores do mundo em cores" src={loginBackground} />
    </Container>
  );
};

export default ForgotPassword;
