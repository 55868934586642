import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { getUrlQuery } from './util';

const SpecialistContext = createContext();

const SpecialistProvider = ({ children }) => {
  const [specialistArray, setSpecialistArray] = useState([]);
  const [currentSpecialist, setCurrentSpecialist] = useState(null);

  const getAllSpecialists = useCallback(async (params = {}) => {
    const newParams = {
      Nome: params.name || undefined,
      EMail: params.email || undefined,
      Situacao: params.status,
      Telefone: params.phoneNumber || undefined,
    };
    try {
      const response = await axios.get(
        `/Especialista?${getUrlQuery(newParams)}`
      );

      setSpecialistArray(response.data.result);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error(
          'Você não possui permissão para visualizar especialistas.'
        );
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao buscar especialistas!');
      }
    }
  }, []);

  const createSpecialist = useCallback(
    async (body) => {
      try {
        const postBody = {
          nome: body.name,
          fotoPerfil: body.photo,
          email: body.email,
          telefone: body.phoneNumber,
          instagram: body.instagram,
          youtube: body.youtube,
          linkedin: body.linkedin,
          facebook: body.facebook,
          tikTok: body.tiktok,
          resumoExperiencia: body.description,
          situacao: true,
        };

        await axios.post('/Especialista', postBody);
        await getAllSpecialists();

        message.success('Especialista cadastrado(a) com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar especialistas.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao cadastrar especialistas!');
        }

        return false;
      }
    },
    [getAllSpecialists]
  );

  const getSpecialistById = useCallback(async (id) => {
    try {
      setCurrentSpecialist(null);

      const response = await axios.get(`/Especialista/${id}`);

      setCurrentSpecialist(response.data.result);

      return response.data.result;
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error(
          'Você não possui permissão para visualizar especialistas.'
        );
      } else {
        message.error('Erro ao buscar especialistas!');
      }
    }
  }, []);

  const updateSpecialist = useCallback(
    async (body) => {
      try {
        const putBody = {
          nome: body.name,
          fotoPerfil: body.photo,
          email: body.email,
          telefone: body.phoneNumber,
          instagram: body.instagram,
          youtube: body.youtube,
          linkedin: body.linkedin,
          facebook: body.facebook,
          tikTok: body.tiktok,
          resumoExperiencia: body.description,
          situacao: true,
        };

        await axios.put(`/Especialista/${body.idEspecialista}`, putBody);
        await getAllSpecialists();

        message.success('Especialista alterado(a) com sucesso!');
        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissão para alterar especialistas.'
          );
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar especialistas!');
        }

        return false;
      }
    },
    [getAllSpecialists]
  );

  const deleteSpecialist = useCallback(
    async (id) => {
      try {
        await axios.delete(`/Especialista/${id}`);
        await getAllSpecialists();

        message.success('Especialista desativado(a) com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error(
            'Você não possui permissão para desativar especialistas.'
          );
        } else {
          message.error('Erro ao desativar especialista!');
        }

        return false;
      }
    },
    [getAllSpecialists]
  );

  const activateSpecialist = useCallback(
    async (id) => {
      try {
        await axios.put(`/Especialista/${id}/Reativar`);
        await getAllSpecialists();

        message.success('Especialista ativado(a) com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para ativar especialistas.');
        } else {
          message.error('Erro ao ativar especialista!');
        }

        return false;
      }
    },
    [getAllSpecialists]
  );

  const clearSpecialistsArray = useCallback(() => {
    setSpecialistArray([]);
  }, []);

  return (
    <SpecialistContext.Provider
      value={{
        specialistsData: { specialistArray, currentSpecialist },
        createSpecialist,
        getAllSpecialists,
        getSpecialistById,
        updateSpecialist,
        deleteSpecialist,
        activateSpecialist,
        clearSpecialistsArray,
      }}
    >
      {children}
    </SpecialistContext.Provider>
  );
};

const useSpecialist = () => {
  const context = useContext(SpecialistContext);

  if (!context) {
    throw new Error('useSpecialist must be used within an SpecialistProvider');
  }

  return context;
};

export { SpecialistProvider, useSpecialist };
