import React, { useState } from 'react';
import { Modal } from 'antd';
import { useContent } from '../../../hooks/Content';

const ToggleContent = ({ onCancel, selected }) => {
  const [loading, setLoading] = useState(false);

  const { deactivateContent, activateContent } = useContent();

  const handleRequest = async () => {
    let result = null;
    setLoading(true);
    if (!selected.situacao) {
      result = await activateContent(selected.idConteudo, selected);
    } else {
      result = await deactivateContent(selected.idConteudo);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };

  return (
    <Modal
      visible
      title={!selected.situacao ? 'Ativar conteúdo' : 'Desativar conteúdo'}
      onCancel={onCancel}
      okText={!selected.situacao ? 'Ativar' : 'Desativar'}
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      {!selected.situacao ? (
        <p>Deseja realmente ativar este conteúdo?</p>
      ) : (
        <p>Deseja realmente desativar este conteúdo?</p>
      )}
    </Modal>
  );
};

export default ToggleContent;
