export const getUrlQuery = (obj) => {
  const queryObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      queryObj[key] = obj[key];
    } else if (obj[key] === false) {
      queryObj[key] = obj[key];
    }
  });

  return new URLSearchParams(queryObj).toString();
};
