import React, { useState } from 'react';
import { Table, Button, Tooltip, Space, Card } from 'antd';
import getColumnSearchProps from '../../../../components/shared/getColumnSearchProps';
import LoadSpinner from '../../../../components/shared/LoadSpinner';
import moment from 'moment';
import 'moment/locale/pt-br';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddSpecialSub from './AddSpecial';
import DeactivateSpecial from './DeactivateSpecial';
import { SUBSCRIPTION_PERMS } from '../../../../constants/Permissions';
import { usePerm } from '../../../../hooks/Permission';

const SpecialSubTable = ({ data, loadingSpecials, handleAddSpecial }) => {
  const columns = [
    {
      title: 'Nome da promoção',
      key: 'name',
      dataIndex: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'Vigência Inicial',
      key: 'vigenciaIni',
      dataIndex: 'vigenciaInicial',
      render: (text) => moment(text).format('L'),
    },
    {
      title: 'Vigência Final',
      key: 'vigenciaFin',
      dataIndex: 'vigencialFinal',
      render: (text) => moment(text).format('L'),
    },
    {
      title: 'Valor',
      key: 'value',
      dataIndex: 'valor',
      sorter: (a, b) => a.valor - b.valor,
      sortDirections: ['ascend', 'descend'],
      render: (text, record) =>
        String(record.valor.toFixed(2)).replace('.', ','),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space>
          {signedUserPerms[SUBSCRIPTION_PERMS.UPDATE] ? (
            <>
              <Tooltip title="Alterar">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedSpecialSub(record);
                    setUpdateSpecialVisible(true);
                  }}
                />
              </Tooltip>

              <Tooltip title="Excluir">
                <Button
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setSelectedSpecialSub(record);
                    setDeactivateSpecialVisible(true);
                  }}
                />
              </Tooltip>
            </>
          ) : null}
        </Space>
      ),
    },
  ];

  const [selectedSpecialSub, setSelectedSpecialSub] = useState(null);
  const [updateSpecialVisible, setUpdateSpecialVisible] = useState(false);
  const [activatepecialVisible, setActivateSpecialVisible] = useState(false);
  const [deactivateSpecialVisible, setDeactivateSpecialVisible] = useState(
    false
  );

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  if (loadingSpecials) {
    return <LoadSpinner />;
  }

  return (
    <Card
      title="Promoções"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        signedUserPerms[SUBSCRIPTION_PERMS.UPDATE] ? (
          <Button
            id="botao_novo_usuario"
            type="primary"
            onClick={() => handleAddSpecial(true)}
          >
            Criar promoção
          </Button>
        ) : null
      }
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(p) => p.idPromo}
        locale={{
          emptyText: 'Nenhum dado encontrado.',
          triggerAsc: null,
          triggerDesc: null,
        }}
      />

      {updateSpecialVisible ? (
        <AddSpecialSub
          visible
          update
          selected={selectedSpecialSub}
          onCancel={() => setUpdateSpecialVisible(false)}
          specialSubs={data}
        />
      ) : null}

      {deactivateSpecialVisible ? (
        <DeactivateSpecial
          visible
          selected={selectedSpecialSub}
          onCancel={() => setDeactivateSpecialVisible(false)}
        />
      ) : null}

      {activatepecialVisible ? (
        <DeactivateSpecial
          visible
          activate
          selected={selectedSpecialSub}
          onCancel={() => setActivateSpecialVisible(false)}
        />
      ) : null}
    </Card>
  );
};

export default SpecialSubTable;
