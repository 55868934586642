import React, { useState, useEffect } from 'react';
import { useContent } from '../../../hooks/Content';
import { CONTENT_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';
import { Formik, Form } from 'formik';
import { Button, Card, message } from 'antd';
import { Spacer, FormLabel } from '../../../components/Form';
import HighLightTransfer from './HighlightTransfer';

const AddHighlight = () => {
  const {
    getAllContent,
    createHighlights,
    getAllHighlights,
    contentData,
  } = useContent();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { permData } = usePerm();
  const { signedUserPerms } = permData;
  const { highlightArray, contentArray } = contentData;

  const [currentSelected, setCurrentSelected] = useState([]);

  useEffect(() => {
    const fetchAllHighlights = async () => {
      await getAllContent({ status: true });
      await getAllHighlights();
    };
    fetchAllHighlights();
  }, [getAllContent, getAllHighlights]);

  useEffect(() => {
    if (highlightArray) {
      const selectHigh = highlightArray.map((h) => h.idConteudo);
      setCurrentSelected(selectHigh);
    }
  }, [highlightArray]);

  const handleRequest = async (formikValues) => {
    const reqBody = formikValues.highlight.map((c) => {
      return {
        idConteudo: c,
      };
    });
    if (reqBody.length === 0) {
      message.error('Deve ter pelo menos 1 Conteúdo em Destaque');
    } else {
      setLoadingRequest(true);
      await createHighlights(reqBody);
      setLoadingRequest(false);
    }
  };

  return (
    <Card title="Conteúdo Destaque">
      <Formik
        enableReinitialize
        initialValues={{ contents: contentArray, highlight: currentSelected }}
        onSubmit={(values) => handleRequest(values)}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <Spacer style={{ width: '100%' }}>
              <FormLabel>Selecione os Conteúdos:</FormLabel>
              <HighLightTransfer formik={formik} contentArray={contentArray} />
            </Spacer>
            {signedUserPerms[CONTENT_PERMS.UPDATE] ? (
              <Spacer style={{ marginLeft: 'auto' }}>
                <Button
                  id="botao_salvar"
                  type="primary"
                  onClick={formik.handleSubmit}
                  loading={loadingRequest}
                >
                  Salvar
                </Button>
              </Spacer>
            ) : null}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default AddHighlight;
