import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch, Route } from 'react-router-dom';

import { Login, ForgotPassword } from '../pages/Login/index';
import Dashboard from '../pages/Dashboard/Dashboard';

const Routes = () => {
  return (
    <Switch>
      <Route path="/esqueci-senha" component={ForgotPassword} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route exact path="/" component={Login} />
    </Switch>
  );
};

export default Routes;
