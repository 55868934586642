import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

const PrivateRoute = ({ component, ...rest }) => {
  const { authData } = useAuth();

  const isAuthenticated = authData?.authToken;

  return isAuthenticated ? (
    <Route component={component} {...rest} />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
