import React, { createContext, useCallback, useContext } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { useContent } from './Content';

const LessonContext = createContext();

const LessonProvider = ({ children }) => {
  const { getContentById, getSections } = useContent();

  const createLesson = useCallback(
    async (body) => {
      try {
        const data = new FormData();
        data.append('titulo', body.title);
        data.append('link', body.video);
        data.append('idModulo', body.sectionId);

        // if (body.newFiles.length > 0) {
        //   data.append(
        //     'arquivo',
        //     body.newFiles.map((f) => {
        //       return {
        //         nomeArquivo: f.name,
        //         arquivo: f.file,
        //       };
        //     })
        //   );
        // }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < body.newFiles.length; i++) {
          data.append('arquivo', body.newFiles[i].file);
        }

        // const filesArray = body.files.map((file) => {
        //   return {
        //     nomeArquivo: file.name,
        //     arquivo: file.file,
        //   };
        // });

        // data.append('arquivos', filesArray);

        if (body.audio?.file) {
          data.append('audio', body.audio?.file);
          data.append('nomeAudio', body.audio?.name);
          data.append('tempoAudio', body.audio?.duration);
        }

        await axios.post('/Aula', data);
        await getContentById(body.contentId);
        await getSections(body.contentId);

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para criar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao criar aula!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const updateLesson = useCallback(
    async (body) => {
      try {
        const data = new FormData();
        data.append('titulo', body.title);
        data.append('link', body.video);
        data.append('idModulo', body.sectionId);

        for (let i = 0; i < body.newFiles.length; i++) {
          data.append('arquivo', body.newFiles[i].file);
        }

        if (body.audio?.file) {
          data.append('audio', body.audio?.file);
          data.append('nomeAudio', body.audio?.name);
          data.append('tempoAudio', body.audio?.duration);
        }

        await axios.put(`/Aula/${body.id}`, data);
        await getContentById(body.contentId);
        await getSections(body.contentId);

        message.success('Aula alterada com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar aula!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const deleteLesson = useCallback(
    async (body) => {
      try {
        await axios.delete(`/Aula/${body.id}`);
        await getContentById(body.contentId);
        await getSections(body.contentId);

        message.success('Conteúdo excluído com sucesso!');

        return true;
      } catch (error) {
        if (error.response.data === 'Não possui permissão') {
          message.error('Você não possui permissão para alterar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao alterar aula!');
        }

        return false;
      }
    },
    [getContentById, getSections]
  );

  const deleteLessonFile = useCallback(async (id) => {
    try {
      console.log('idDeletado', id);
      await axios.delete(`/ArquivosAula/${id}`);
    } catch (error) {
      if (error.response.data === 'Não possui permissão') {
        message.error('Você não possui permissão para alterar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao alterar aula!');
      }

      return false;
    }
  }, []);

  return (
    <LessonContext.Provider
      value={{ createLesson, updateLesson, deleteLesson, deleteLessonFile }}
    >
      {children}
    </LessonContext.Provider>
  );
};

const useLesson = () => {
  const context = useContext(LessonContext);

  if (!context) {
    throw new Error('useContent must be used within an ContentProvider');
  }

  return context;
};

export { LessonProvider, useLesson };
