import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { Formik, Form } from 'formik';
import {
  FormLabel,
  Spacer,
  ErrorMsg,
} from '../../../../../components/Form/index';
import * as yup from 'yup';
import { useContent } from '../../../../../hooks/Content';

const schema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório.'),
});

const AddSection = ({ selected, onCancel, contentId }) => {
  const [loading, setLoading] = useState(false);
  const { createContentSection, updateContentSection } = useContent();

  const handleRequest = async (values) => {
    let result = null;

    setLoading(true);
    if (selected) {
      result = await updateContentSection({
        ...values,
        status: true,
        id: selected.idModulo,
      });
    } else {
      result = await createContentSection(values);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };

  return (
    <Modal
      visible
      footer={null}
      onCancel={onCancel}
      title={selected ? 'Alterar módulo' : 'Criar módulo'}
    >
      <Formik
        validationSchema={schema}
        initialValues={selected ? { name: selected.titulo } : { name: '' }}
        onSubmit={(values) => {
          handleRequest({ title: values.name, contentId });
        }}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel>Nome do módulo:*</FormLabel>
            <Input
              name="campo_nome"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              onBlur={formik.handleBlur}
              maxLength={90}
            />
            <ErrorMsg name="name" />

            <Spacer style={{ marginLeft: 'auto' }}>
              <Button
                id="botao_salvar"
                type="primary"
                onClick={formik.handleSubmit}
                loading={loading}
              >
                Salvar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddSection;
