import React from 'react';
import styled from 'styled-components';
import { Input, Button, Select } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer } from '../../../components/Form/index';
import { useSpecialist } from '../../../hooks/Specialist';

const SearchRole = ({ loadingFunction }) => {
  const { getAllSpecialists } = useSpecialist();
  const handleSearch = async (values) => {
    loadingFunction(true);
    await getAllSpecialists(values);
    loadingFunction(false);
  };

  return (
    <FormContainer>
      <FormTitle>Filtrar especialistas</FormTitle>
      <Formik initialValues={{ name: '', status: '' }} onSubmit={handleSearch}>
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <HorizontalContainer>
              <FieldContainer style={{ marginRight: '10px' }}>
                <FormLabel>Nome:</FormLabel>
                <Input
                  id="campo_nome"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  onBlur={formik.handleBlur}
                />
              </FieldContainer>

              <FieldContainer>
                <FormLabel>Situação:</FormLabel>
                <Select
                  id="campo_situacao"
                  allowClear
                  style={{ width: '100%' }}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue('status', value)}
                  value={formik.values.status}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option value>Ativo</Select.Option>
                  <Select.Option value={false}>Desativado</Select.Option>
                </Select>
              </FieldContainer>
            </HorizontalContainer>

            <Spacer style={{ margin: '15px 0 0 auto' }}>
              <Button type="primary" onClick={formik.handleSubmit}>
                Pesquisar
              </Button>
            </Spacer>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

const FormContainer = styled.div`
  margin-bottom: 20px;
`;

const FormTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 800;
`;

export default SearchRole;
