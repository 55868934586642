import React from 'react';
import styled from 'styled-components';
import AddContentForm from '../AddContentForm';

const GeneralInfo = ({ content, ...props }) => {
  return (
    <Container>
      <AddContentForm {...props} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// const HorizontalContainer = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const Image = styled.img`
//   max-width: 200px;
//   height: auto;
// `;

// const ImageContainer = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-evenly;
// `;

export default GeneralInfo;
