import React, { useState, useEffect } from 'react';
import { Card, Input, DatePicker, InputNumber, Select, Button } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, ErrorMsg, Spacer } from '../../../components/Form/index';
import locale from 'antd/es/date-picker/locale/pt_BR';
import styled from 'styled-components';
import moment from 'moment';
import { addSubscriptionSchema } from './formValidation';
import { useSubscription } from '../../../hooks/Subscription';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import GoBackButton from '../../../components/shared/GoBackButton';
import { useHistory } from 'react-router-dom';

const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

const AddSubscription = ({ match }) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const {
    createSubscription,
    getSubById,
    updateSubscription,
    subsData,
  } = useSubscription();

  const { currentSub } = subsData;

  const idPlano = match.params.idPlano || null;
  const history = useHistory();

  useEffect(() => {
    if (idPlano) {
      const fetchSub = async () => {
        setLoadingFetch(true);
        await getSubById(idPlano);
        setLoadingFetch(false);
      };
      fetchSub();
    }
  }, [idPlano, getSubById]);

  const handleRequest = async (formikValues, formikBag) => {
    let result = null;

    setLoadingRequest(true);
    if (idPlano) {
      result = await updateSubscription({ id: idPlano, body: formikValues });
    } else {
      result = await createSubscription(formikValues);
    }

    setLoadingRequest(false);

    if (result) {
      history.push('/dashboard/planos');
    }
  };

  if (loadingFetch) {
    return <LoadSpinner />;
  }

  return (
    <>
      <GoBackButton to="/dashboard/planos" />
      <Card title={idPlano ? 'Alterar Plano' : 'Cadastrar Plano'}>
        <Formik
          initialValues={
            idPlano
              ? {
                  name: currentSub?.nome || '',
                  initialDate: moment(currentSub?.vigenciaInicial),
                  finalDate: currentSub?.vigenciaFinal
                    ? moment(currentSub.vigenciaFinal)
                    : '',
                  timeSpan: currentSub?.periodicidade,
                  description: currentSub?.descricao,
                  price: currentSub?.valor,
                }
              : {
                  name: '',
                  initialDate: moment(),
                  finalDate: '',
                  timeSpan: '',
                  description: '',
                  price: 0,
                }
          }
          validationSchema={addSubscriptionSchema}
          onSubmit={(values, formikBag) => handleRequest(values, formikBag)}
        >
          {(formik) => (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <FormLabel>Nome do plano:*</FormLabel>
              <Input
                id="campo_nome_plano"
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                onBlur={formik.handleBlur}
                maxLength={90}
              />
              <ErrorMsg name="name" />

              <Spacer>
                <FormLabel>Vigência do plano:</FormLabel>
                <HorizontalContainer>
                  <DatePicker
                    id="campo_data_inicio"
                    locale={locale}
                    onBlur={formik.handleBlur}
                    format={dateFormat}
                    style={{ marginRight: '20px', width: 200 }}
                    value={formik.values.initialDate}
                    onChange={(e) => {
                      formik.setFieldValue('initialDate', e);
                      formik.setFieldValue('finalDate', '');
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                  até
                  <DatePicker
                    id="campo_data_fim"
                    locale={locale}
                    onBlur={formik.handleBlur}
                    format={dateFormat}
                    style={{ marginLeft: '20px', width: 200 }}
                    value={formik.values.finalDate}
                    onChange={(e) => formik.setFieldValue('finalDate', e)}
                    disabledDate={(current) =>
                      current < formik.values.initialDate
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </HorizontalContainer>
                <ErrorMsg name="initialDate" />
              </Spacer>

              <HorizontalContainer>
                <Spacer>
                  <FormLabel>Valor (R$):*</FormLabel>
                  <InputNumber
                    id="campo_valor_plano"
                    onChange={(value) => formik.setFieldValue('price', value)}
                    value={formik.values.price}
                    onBlur={formik.handleBlur}
                    decimalSeparator=","
                    precision={2}
                    step={0.01}
                    style={{ width: 200, marginRight: 30 }}
                  />
                  <ErrorMsg name="price" />
                </Spacer>

                <Spacer>
                  <FormLabel>Periodicidade:*</FormLabel>
                  <Select
                    id="campo_periodicidade"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Selecione uma opção"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue('timeSpan', value)
                    }
                    value={formik.values.timeSpan}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option value="Mensal">Mensal</Option>
                    <Option value="Semestral">Semestral</Option>
                    <Option value="Anual">Anual</Option>
                  </Select>
                  <ErrorMsg name="timeSpan" />
                </Spacer>
              </HorizontalContainer>

              <Spacer>
                <FormLabel>Situação:</FormLabel>
                <Input
                  disabled
                  id="campo_situacao"
                  value="Ativo"
                  style={{ width: 200 }}
                />
              </Spacer>

              <Spacer>
                <FormLabel>Descrição:*</FormLabel>
                <Input.TextArea
                  id="campo_descricao_plano"
                  onChange={(e) =>
                    formik.setFieldValue('description', e.target.value)
                  }
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  style={{ height: 200 }}
                  maxLength={400}
                />
                <ErrorMsg name="description" />
              </Spacer>

              <Spacer style={{ marginLeft: 'auto' }}>
                <Button
                  type="primary"
                  id="botao_salvar"
                  onClick={formik.handleSubmit}
                  loading={loadingRequest}
                >
                  Salvar
                </Button>
              </Spacer>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default AddSubscription;
