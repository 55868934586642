import React, { useState } from 'react';
import { Modal } from 'antd';
import { useLesson } from '../../../../../hooks/Lesson';

const DeactivateLesson = ({ onCancel, selected, section }) => {
  const [loading, setLoading] = useState(false);

  const { deleteLesson } = useLesson();

  const handleRequest = async () => {
    setLoading(true);
    const result = await deleteLesson({
      id: selected.id,
      contentId: section.idConteudo,
    });
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible
      title="Excluir Aula"
      onCancel={onCancel}
      okText="Excluir"
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      <p>Deseja realmente excluir esta aula?</p>
    </Modal>
  );
};

export default DeactivateLesson;
