import * as yup from 'yup';

export const addContentSchema = yup.object().shape({
  contentPhoto: yup.string().required('Este campo é obrigatório.'),
  title: yup.string().required('Este campo é obrigatório.'),
  description: yup.string().optional('Este campo é obrigatório.'),
  objective: yup.string().optional('Este campo é obrigatório.'),
  detailPhoto: yup.string().required('Este campo é obrigatório.'),
  specialist: yup.string().required('Este campo é obrigatório.'),
  audience: yup.string().optional('Este campo é obrigatório.'),
  hasCertificate: yup.boolean().required('Este campo é obrigatório.'),
  aditional: yup.boolean().required('Este campo é obrigatório.'),
  allowOffline: yup.boolean().required('Este campo é obrigatório.'),
  aditionalValue: yup
    .number()
    .typeError('Este campo é obrigatório.')
    .min(0, 'O valor deve ser maior ou igual a zero!')
    .required('Este campo é obrigatório.'),
  aditionalPromoValue: yup
    .number()
    .typeError('Insira um valor numérico.')
    .min(0, 'O valor deve ser maior ou igual a zero!')
    .optional(),
  category: yup.string().required('Este campo é obrigatório.'),
  subject: yup.string().required('Este campo é obrigatório.'),
  duration: yup.string().required('Este campo é obrigatório.'),
});
