import React, { createContext, useState, useCallback, useContext } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';
import { usePerm } from './Permission';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { clearSignedUserPerms } = usePerm();

  const [authData, setAuthData] = useState(() => {
    const data = localStorage.getItem('@MundoEmCores:authData');

    if (data) {
      const localData = JSON.parse(data);
      axios.defaults.headers.authorization = `Bearer ${localData.authToken}`;
      return localData;
    }

    return null;
  });

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await axios.post('/Auth/login', {
        userName: email,
        password,
      });

      const { idUser } = response.data;
      const id = String(idUser).split('-')[1];

      const data = { ...response.data, idUser: id, email };

      setAuthData(data);

      axios.defaults.headers.authorization = `Bearer ${data.authToken}`;

      localStorage.setItem('@MundoEmCores:authData', JSON.stringify(data));
    } catch (error) {
      message.error('Email ou senha incorretos!');
    }
  }, []);

  const changeUserPassword = useCallback(
    async (newPassword) => {
      try {
        const response = await axios.post('/Usuario/ForgotPassword', {
          email: authData?.email,
          newPassword,
        });

        message.success('Senha alterada com sucesso!');
        return response.data;
      } catch (error) {
        message.error('Erro ao tentar trocar a senha!');
      }
    },
    [authData]
  );

  const passwordForgot = useCallback(async (email) => {
    try {
      await axios.post('/Usuario/ForgotPassword', {
        email,
      });

      message.success(
        'Enviamos para o e-mail informado informações sobre o login!'
      );
      return true;
    } catch (error) {
      message.success(
        'Enviamos para o e-mail informado informações sobre o login!'
      );
      return true;
    }
  }, []);

  const logout = useCallback(() => {
    try {
      localStorage.removeItem('@MundoEmCores:authData');
      clearSignedUserPerms();
      setAuthData(null);
      return true;
    } catch (error) {
      message.error('Erro ao fazer logout!');
      return false;
    }
  }, [clearSignedUserPerms]);

  return (
    <AuthContext.Provider
      value={{ signIn, authData, changeUserPassword, passwordForgot, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
