import React from 'react';
import { Menu } from 'antd';

const DropdownMenu = ({ handleLogout, handleUserData }) => {
  return (
    <Menu style={{ border: 'solid 1px #d1d1d1', borderRadius: '6px' }}>
      <Menu.Item
        style={{ justifyContent: 'center', display: 'flex' }}
        onClick={handleUserData}
        id="rota-meus-dados"
      >
        Meus Dados
      </Menu.Item>
      <Menu.Item
        id="botao_sair"
        style={{ justifyContent: 'center', display: 'flex', color: 'red' }}
        onClick={handleLogout}
      >
        Sair
      </Menu.Item>
    </Menu>
  );
};

export default DropdownMenu;
