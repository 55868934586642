import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSubscription } from '../../../../hooks/Subscription';

const DeactivateSpecial = ({ visible, onCancel, selected }) => {
  const [loading, setLoading] = useState(false);

  const { deactivateSpecial } = useSubscription();

  const handleRequest = async () => {
    setLoading(true);
    const result = await deactivateSpecial(selected);
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible={visible}
      title="Excluir Promoção"
      onCancel={onCancel}
      okText="Excluir"
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      <p>Deseja realmente excluir esta promoção?</p>
    </Modal>
  );
};

export default DeactivateSpecial;
