import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { useContent } from '../../../../hooks/Content';
import { useTag } from '../../../../hooks/Tag';
import LoadSpinner from '../../../../components/shared/LoadSpinner';
import GoBackButton from '../../../../components/shared/GoBackButton';
import GeneralInfo from './GeneralInfo';
import AddMedia from '../AddContent/AddMedia';
import SubscriptionList from '../AddContent/Subscription/SubscriptionList';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

const ContentDetail = ({ match, location, ...props }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { getAllTags } = useTag();

  const {
    getContentById,
    contentData,
    getSections,
    clearCurrentContent,
  } = useContent();
  const { currentContent } = contentData;
  const { idConteudo } = match.params;

  useEffect(() => {
    clearCurrentContent();
    const fetchContent = async () => {
      setLoading(true);
      await getContentById(idConteudo);
      await getSections(idConteudo);
      await getAllTags();
      setLoading(false);
    };
    fetchContent();

    return () => clearCurrentContent();
  }, [
    idConteudo,
    getContentById,
    getSections,
    getAllTags,
    clearCurrentContent,
  ]);

  const handleTabChange = (tabName) => {
    history.push(`${match.url}/${tabName}`);
  };

  const handleActiveTab = () => {
    const key = location.pathname.split('/').pop();
    return key;
  };

  return (
    <>
      <GoBackButton to="/dashboard/tabela-conteudos" />
      <Card>
        {loading || currentContent === null ? (
          <LoadSpinner />
        ) : (
          <Tabs
            defaultActiveKey="info-gerais"
            onChange={handleTabChange}
            activeKey={handleActiveTab()}
          >
            <TabPane
              tab="Informações Gerais"
              key="info-gerais"
              id="tab-info-gerais"
            >
              <GeneralInfo
                content={currentContent}
                {...props}
                match={match}
                history={history}
              />
            </TabPane>
            <TabPane tab="Conteúdo" key="conteudo">
              <AddMedia contentId={currentContent.idConteudo} />
            </TabPane>

            <TabPane tab="Planos" key="planos">
              <SubscriptionList
                subList={[]}
                contentId={currentContent.idConteudo}
              />
            </TabPane>
          </Tabs>
        )}
      </Card>
    </>
  );
};

export default ContentDetail;
