import React, { createContext, useCallback, useContext, useState } from 'react';
import { message } from 'antd';
import axios from './axiosInstance';

const TagContext = createContext();

const TagProvider = ({ children }) => {
  const [tagsArray, setTagsArray] = useState(null);
  const [displayTagsObj, setDisplayTagsObj] = useState(null);

  const getAllTags = useCallback(async () => {
    try {
      const response = await axios.get(`/Tag`);

      const tagsTransformed = {};

      response.data.result.forEach((t) => {
        tagsTransformed[t.idTag] = t.nomeTag;
      });

      setTagsArray(response.data.result);
      setDisplayTagsObj(tagsTransformed);
    } catch (error) {
      if (error.response?.data === 'Não possui permissão') {
        message.error('Você não possui permissão para visualizar conteúdo.');
      } else if (error.response.data) {
        message.error(error.response.data);
      } else {
        message.error('Erro ao buscar tags!');
      }
    }
  }, []);

  const createTag = useCallback(
    async (tagName) => {
      try {
        const body = {
          nomeTag: tagName,
        };
        await axios.post(`/Tag`, body);
        await getAllTags();

        return true;
      } catch (error) {
        if (error.response?.data === 'Não possui permissão') {
          message.error('Você não possui permissão para visualizar conteúdo.');
        } else if (error.response.data) {
          message.error(error.response.data);
        } else {
          message.error('Erro ao buscar tags!');
        }
        return false;
      }
    },
    [getAllTags]
  );

  return (
    <TagContext.Provider
      value={{
        tagsData: { tagsArray, displayTagsObj },
        getAllTags,
        createTag,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};

const useTag = () => {
  const context = useContext(TagContext);

  if (!context) {
    throw new Error('useTag must be used within an TagProvider');
  }

  return context;
};

export { TagProvider, useTag };
