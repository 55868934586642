import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer, ErrorMsg } from '../../../components/Form/index';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import { Modal, Input, Select, Button } from 'antd';
import { addUserSchema } from './formSchema';
import { useUser } from '../../../hooks/User';
import { useRole } from '../../../hooks/Role';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

const { Option } = Select;

const AddUser = ({ visible, onCancel, update, selectedUser }) => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const { createUser, updateUser } = useUser();
  const { getRoles, rolesData } = useRole();
  const { rolesArray } = rolesData;

  useEffect(() => {
    const fetchRoles = async () => {
      setLoadingRoles(true);
      await getRoles({ status: true });
      setLoadingRoles(false);
    };
    fetchRoles();
  }, [getRoles]);

  const handleRequest = async (formValues) => {
    let result = null;

    const formCopy = JSON.parse(JSON.stringify(formValues));

    const selectedRole = rolesArray.filter(
      (r) => r.nome === formValues.role
    )[0];

    formCopy.role = selectedRole.codigo;

    setLoadingRequest(true);
    if (update) {
      result = await updateUser({
        id: selectedUser.codigo,
        ...formCopy,
      });
    } else {
      result = await createUser(formCopy);
    }
    setLoadingRequest(false);

    if (result) {
      onCancel();
    }
  };

  return (
    <Modal
      visible={visible}
      title={update ? 'Alterar Usuário' : 'Criar Usuário'}
      onCancel={onCancel}
      footer={null}
    >
      <Formik
        initialValues={
          update
            ? {
                name: selectedUser.nome,
                phoneNumber: selectedUser.telefone || '',
                phoneType: selectedUser.telefone?.length === 15 ? 0 : 1,
                email: selectedUser.email,
                role: selectedUser.perfil?.nome || '',
              }
            : {
                name: '',
                phoneType: 0,
                phoneNumber: '',
                email: '',
                role: '',
              }
        }
        validationSchema={addUserSchema}
        onSubmit={(values) => handleRequest(values)}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            {loadingRoles || rolesArray === null ? (
              <LoadSpinner />
            ) : (
              <>
                <Spacer>
                  <FormLabel>Nome:*</FormLabel>
                  <Input
                    id="campo_nome"
                    value={formik.values.name}
                    onChange={(e) =>
                      formik.setFieldValue('name', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    maxLength={90}
                  />
                  <ErrorMsg name="name" />
                </Spacer>

                <Spacer>
                  <FormLabel>E-mail:*</FormLabel>
                  <Input
                    id="campo_email"
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue('email', e.target.value)
                    }
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMsg name="email" />
                </Spacer>

                <HorizontalContainer>
                  <Spacer style={{ width: '100%', marginRight: '10px' }}>
                    <FormLabel>Tipo de telefone:</FormLabel>
                    <Select
                      style={{ width: '100%' }}
                      onBlur={formik.handleBlur}
                      onChange={(value) => {
                        formik.setFieldValue('phoneNumber', '');
                        formik.setFieldValue('phoneType', value);
                      }}
                      value={formik.values.phoneType}
                    >
                      <Option value={0}>Celular</Option>
                      <Option value={1}>Telefone fixo</Option>
                    </Select>
                  </Spacer>

                  {formik.values.phoneType === 0 ? (
                    <Spacer style={{ width: '100%' }}>
                      <FormLabel>Celular:</FormLabel>
                      <InputMask
                        id="campo_telefone"
                        mask="(99) 99999-9999"
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        onChange={(e) =>
                          formik.setFieldValue('phoneNumber', e.target.value)
                        }
                      >
                        <Input />
                      </InputMask>
                    </Spacer>
                  ) : (
                    <Spacer style={{ width: '100%' }}>
                      <FormLabel>Telefone:</FormLabel>
                      <InputMask
                        id="campo_telefone"
                        mask="(99) 9999-9999"
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        onChange={(e) =>
                          formik.setFieldValue('phoneNumber', e.target.value)
                        }
                      >
                        <Input />
                      </InputMask>
                    </Spacer>
                  )}
                </HorizontalContainer>

                <Spacer>
                  <FormLabel>Perfil:</FormLabel>
                  <Select
                    id="campo_perfil"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Selecione um perfil"
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue('role', value)}
                    value={formik.values.role}
                  >
                    {rolesArray?.map((r) => (
                      <Option value={r.nome} key={`${r.codigo}-${r.nome}`}>
                        {r.nome}
                      </Option>
                    ))}
                  </Select>
                  <ErrorMsg name="role" />
                </Spacer>

                <Spacer style={{ marginLeft: 'auto' }}>
                  <Button
                    type="primary"
                    id="botao_salvar"
                    onClick={formik.handleSubmit}
                    loading={loadingRequest}
                  >
                    Salvar
                  </Button>
                </Spacer>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
`;

export default AddUser;
