import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSubscription } from '../../../hooks/Subscription';

const ToggleSubscription = ({ onCancel, selected, activate }) => {
  const [loading, setLoading] = useState(false);

  const { deleteSubscription, activateSubscription } = useSubscription();

  const handleRequest = async () => {
    let result = null;
    setLoading(true);
    if (activate) {
      result = await activateSubscription(selected);
    } else {
      result = await deleteSubscription(selected);
    }
    setLoading(false);

    if (result) {
      onCancel();
    }
  };
  return (
    <Modal
      visible
      title={activate ? 'Ativar Plano' : 'Desativar Plano'}
      onCancel={onCancel}
      okText={activate ? 'Ativar' : 'Desativar'}
      cancelText="Cancelar"
      onOk={handleRequest}
      confirmLoading={loading}
    >
      <p>Deseja realmente {activate ? 'ativar' : 'desativar'} este plano?</p>
    </Modal>
  );
};

export default ToggleSubscription;
