/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import { Card, Button, Select, Transfer } from 'antd';
import { Formik, Form } from 'formik';
import { FormLabel, Spacer } from '../../../components/Form/index';
import { useRole } from '../../../hooks/Role';
import { usePerm } from '../../../hooks/Permission';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import debounce from 'lodash.debounce';
import { PERMISSION_PERMS } from '../../../constants/Permissions';

const { Option } = Select;

const filter = (inputValue, option) =>
  option.name.toLowerCase().indexOf(inputValue) > -1;

const AddPermission = () => {
  const { getRoles, rolesData } = useRole();
  const { rolesArray } = rolesData;
  const {
    getPermissions,
    getPermissionByRole,
    changePermissions,
    permData,
  } = usePerm();
  const { signedUserPerms } = permData;
  const [formikObj, setFormikObj] = useState(null);
  const [perms, setPerms] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingPerms, setLoadingPerms] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const handleRoleSearch = debounce(async (info) => {
    setLoadingRoles(true);
    await getRoles({ name: info, status: true });
    setLoadingRoles(false);
  }, 300);

  const handleInitialPerms = useCallback(
    (allPerms, selecPermsArray) => {
      const selecKeys = selecPermsArray.map((p) => p.permissaoId);

      const allPermsTranformed = allPerms.map((p) => ({
        key: p.id,
        name: p.nome,
      }));

      setPerms(allPermsTranformed);
      formikObj.setFieldValue('perms', selecKeys);
    },
    [formikObj]
  );

  useEffect(() => {
    const fetchInitialRoles = async () => {
      setLoadingRoles(true);
      await getRoles({ name: '', status: true });
      setLoadingRoles(false);
    };
    fetchInitialRoles();
  }, [getRoles]);

  useEffect(() => {
    if (selectedRole !== null) {
      const fetchAllPermissions = async () => {
        setLoadingPerms(true);
        const result = await getPermissions();
        const result2 = await getPermissionByRole(selectedRole);
        handleInitialPerms(result, result2);
        setLoadingPerms(false);
      };
      fetchAllPermissions();
    }
  }, [selectedRole, getPermissions, handleInitialPerms, getPermissionByRole]);

  const getRoleId = (role) =>
    rolesArray.filter((r) => r.nome === role)[0].codigo;

  const handleTransfer = (event, formik) => {
    let selectedKeys = null;

    if (event.dir === 'right') {
      selectedKeys = formik.values.perms.concat(event.source);
    } else {
      selectedKeys = formik.values.perms.filter(
        (key) => !event.target.includes(key)
      );
    }
    formik.setFieldValue('perms', selectedKeys);
  };

  const handleRequest = async (formikValues) => {
    const reqBody = formikValues.perms.map((p) => {
      return {
        perfilId: getRoleId(formikValues.role),
        permissaoId: p,
      };
    });

    setLoadingRequest(true);
    await changePermissions(reqBody);
    setLoadingRequest(false);
  };

  return (
    <Card title="Permissões de Perfil">
      <Formik
        initialValues={{ role: null, perms: [] }}
        onSubmit={(values) => handleRequest(values)}
      >
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <Spacer>
              <FormLabel>Perfil:</FormLabel>
              <Select
                id="campo_perfil"
                showSearch
                onSearch={handleRoleSearch}
                placeholder="Selecione um perfil"
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue('role', value);
                  setSelectedRole(getRoleId(value));
                  setFormikObj(formik);
                }}
                value={formik.values.role}
                style={{ width: '100%', maxWidth: '250px' }}
                notFoundContent={loadingRoles ? <LoadSpinner /> : null}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {rolesArray?.map((r) => (
                  <Option value={r.nome} key={`${r.codigo}-${r.nome}`}>
                    {r.nome}
                  </Option>
                ))}
              </Select>
            </Spacer>

            {selectedRole === null ? (
              loadingPerms ? (
                <LoadSpinner />
              ) : null
            ) : loadingPerms ? (
              <LoadSpinner />
            ) : (
              <>
                <Spacer style={{ width: '100%' }}>
                  <FormLabel>Selecione as Permissões:</FormLabel>

                  <Transfer
                    showSearch
                    dataSource={perms}
                    targetKeys={formik.values.perms}
                    render={(item) => item.name}
                    filterOption={filter}
                    onChange={(source, dir, target) =>
                      handleTransfer({ source, dir, target }, formik)
                    }
                    listStyle={{ width: '100%', height: '100%' }}
                    titles={['Permissões', 'Permissões do Perfil']}
                    locale={{
                      itemsUnit: 'itens',
                      notFoundContent: 'Lista vazia',
                      searchPlaceholder: 'Pesquise aqui',
                    }}
                  />
                </Spacer>

                {signedUserPerms[PERMISSION_PERMS.UPDATE] ? (
                  <Spacer style={{ marginLeft: 'auto' }}>
                    <Button
                      id="botao_salvar"
                      type="primary"
                      onClick={formik.handleSubmit}
                      loading={loadingRequest}
                    >
                      Salvar
                    </Button>
                  </Spacer>
                ) : null}
              </>
            )}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default AddPermission;
