/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Tooltip, Space, Switch } from 'antd';
import { EditOutlined, FilterOutlined } from '@ant-design/icons';
import { AddUser, ToggleUser, SearchUsers } from './index';
import { useUser } from '../../../hooks/User';
import LoadSpinner from '../../../components/shared/LoadSpinner';
import getColumnSearchProps from '../../../components/shared/getColumnSearchProps';
import { USER_PERMS } from '../../../constants/Permissions';
import { usePerm } from '../../../hooks/Permission';

const Users = () => {
  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('nome'),
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Perfil',
      key: 'role',
      dataIndex: ['perfil', 'nome'],
      sorter: (a, b) => a.perfil.nome.localeCompare(b.perfil.nome),
      sortDirections: ['ascend', 'descend'],
    },
    { title: 'Telefone', key: 'phoneNumber', dataIndex: 'telefone' },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space>
          {signedUserPerms[USER_PERMS.DELETE] ? (
            <Tooltip title={record.dataDesativacao ? 'Ativar' : 'Desativar'}>
              <Switch
                checked={!record.dataDesativacao}
                onChange={() => {
                  setSelectedUser(record);
                  setDeactivateUserVisible(true);
                }}
              />
            </Tooltip>
          ) : null}

          {signedUserPerms[USER_PERMS.UPDATE] ? (
            <Tooltip title="Alterar">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedUser(record);
                  setUpdateUserVisible(true);
                }}
              />
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  const [createUserVisible, setCreateUserVisible] = useState(false);
  const [updateUserVisible, setUpdateUserVisible] = useState(false);
  const [deactivateUserVisible, setDeactivateUserVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const { permData } = usePerm();
  const { signedUserPerms } = permData;

  const { getUsers, usersData } = useUser();
  const { userList } = usersData;

  useEffect(() => {
    const getUsersList = async () => {
      setLoadingUsers(true);
      await getUsers();
      setLoadingUsers(false);
    };
    getUsersList();
  }, [getUsers]);

  return (
    <Card
      title="Usuários"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Space>
          {searchVisible ? (
            <Button
              danger
              id="botao_desativar_filtro"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(false)}
            >
              Desativar filtro
            </Button>
          ) : (
            <Button
              id="botao_filtrar"
              icon={<FilterOutlined />}
              onClick={() => setSearchVisible(true)}
            >
              Filtrar Resultado
            </Button>
          )}

          {signedUserPerms[USER_PERMS.CREATE] ? (
            <Button
              id="botao_novo_usuario"
              type="primary"
              onClick={() => setCreateUserVisible(true)}
            >
              Novo Usuário
            </Button>
          ) : null}
        </Space>
      }
    >
      {searchVisible ? <SearchUsers /> : null}

      {loadingUsers || userList === null ? (
        <LoadSpinner />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={userList}
            rowKey={(record) => record.codigo}
            scroll={{ x: true }}
            locale={{
              emptyText: 'Nenhum dado encontrado.',
              triggerAsc: null,
              triggerDesc: null,
            }}
          />
        </>
      )}

      {createUserVisible ? (
        <AddUser visible onCancel={() => setCreateUserVisible(false)} />
      ) : null}

      {updateUserVisible ? (
        <AddUser
          visible
          onCancel={() => setUpdateUserVisible(false)}
          update
          selectedUser={selectedUser}
        />
      ) : null}

      {deactivateUserVisible ? (
        <ToggleUser
          visible
          onCancel={() => setDeactivateUserVisible(false)}
          selectedUser={selectedUser}
        />
      ) : null}
    </Card>
  );
};

export default Users;
