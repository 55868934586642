import React from 'react';
import { Tag } from 'antd';
import { useTag } from '../../../hooks/Tag';

const DisplayTags = ({ tagsArray, handleDeleteTag }) => {
  const { tagsData } = useTag();
  const { displayTagsObj } = tagsData;

  const handleDelete = (e, tagId) => {
    e.preventDefault();
    let newTags = [];

    if (tagsArray.length !== 1) {
      newTags = tagsArray.filter((t) => t.id !== tagId);
    } else {
      newTags = [...tagsArray];
      newTags.forEach((t) => {
        t.idTag = 0;
      });
    }

    newTags.forEach((t) => {
      delete t.id;
      delete t.idTagNavigation;
    });

    handleDeleteTag(newTags);
  };

  return (
    <div style={{ display: 'flex', marginTop: '5px' }}>
      {tagsArray?.map((t) => (
        <Tag
          style={{ display: 'flex', alignItems: 'center', padding: '5px 10px' }}
          key={`${t.id}${t.idTag}`}
          closable
          onClose={(e) => handleDelete(e, t.id)}
        >
          <span>{displayTagsObj[t.idTag]}</span>
        </Tag>
      ))}
    </div>
  );
};

export default DisplayTags;
