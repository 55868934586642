import React, { useState, useEffect } from 'react';
import { Card, Input, Button } from 'antd';
import { FormLabel, Spacer } from '../../../components/Form/index';
import { ChangePassword } from './index';
import { useUser } from '../../../hooks/User';
import { useAuth } from '../../../hooks/Auth';
import LoadSpinner from '../../../components/shared/LoadSpinner';

const UserData = () => {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const openChangePassword = () => setChangePasswordVisible(true);
  const closeChangePassword = () => setChangePasswordVisible(false);

  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);

  const { authData } = useAuth();
  const { getUserById } = useUser();

  useEffect(() => {
    const getCurrentUserData = async () => {
      setLoadingUserData(true);
      const currentUserData = await getUserById(authData.idUser);
      setUserData(currentUserData);
      setLoadingUserData(false);
    };

    getCurrentUserData();
  }, [authData.idUser, getUserById]);

  return (
    <Card
      title="Meus Dados"
      extra={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button
          type="primary"
          id="botao_alterar_senha"
          onClick={openChangePassword}
        >
          Alterar senha
        </Button>
      }
    >
      {loadingUserData || userData === null ? (
        <LoadSpinner />
      ) : (
        <>
          <FormLabel>Nome:</FormLabel>
          <Input disabled value={userData?.nome} size="large" />

          <Spacer>
            <FormLabel>Email:</FormLabel>
            <Input disabled value={userData?.email} size="large" />
          </Spacer>

          <Spacer>
            <FormLabel>Telefone:</FormLabel>
            <Input disabled value={userData?.telefone} size="large" />
          </Spacer>

          <Spacer>
            <FormLabel>Perfil:</FormLabel>
            <Input disabled value={userData?.perfil.nome} size="large" />
          </Spacer>
        </>
      )}

      <ChangePassword
        visible={changePasswordVisible}
        onCancel={closeChangePassword}
      />
    </Card>
  );
};

export default UserData;
